// End Activate grid/slides toggle //

.gallery {
  margin-top: 6em;
  margin-bottom: 4.125em;

  @include breakpoint(S) {
    margin-top: 5.5em;
  }
  &.grid {
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-auto-rows: 8em;
    grid-auto-flow: dense;

    @include breakpoint(XS) {
      column-count: 1;
      display: block;
      column-count: auto;
    }
    .grid-item {
      display: block;
      position: relative;
      margin: 0.5em;
      width: calc(100% - 1em);
      grid-row-end: span 2;
      overflow: hidden;

      @include breakpoint(XS) {
        height: 14em;
      }
      &:nth-child(16n + 3) {
        grid-row-end: span 4;
        grid-column: span 2;
      }
      &:nth-child(16n + 4) {
        grid-row-end: span 4;
      }
      &:nth-child(16n + 6) {
        grid-column: span 2;
      }
      &:nth-child(16n + 8) {
        grid-row-end: span 4;
        grid-column: span 2;
      }
      &:nth-child(16n + 9) {
        grid-column: span 2;
      }
      &:nth-child(16n + 12) {
        grid-column: span 2;
      }
      &:nth-child(16n + 13) {
        grid-row-end: span 4;
      }
      &:nth-child(16n + 15) {
        grid-column: span 2;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %nav-transition;
      }
      &:after {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %transition;
      }
      &.overlay-active {
        .grid-item-caption {
          opacity: 1;
        }
        &:hover {
          figure {
            .grid-item-caption {
              background: rgba(#000, 0.3);
            }
          }
        }
      }
      &:hover {
        .grid-item-caption {
          opacity: 1;
        }
        .arrow-icon {
          transform: translateX(0.25em);
        }
        img {
          transform: scale(1.1) rotate(3deg);
        }
      }
      figure {
        margin-bottom: 0;

        .grid-item-caption {
          @extend %transition;
          background: rgba(#000, 0.5);
          text-align: center;
          color: $color-body-cream;
          opacity: 0;
          position: absolute;
          padding: 1em;
          top: 0;
          height: 100%;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .caption-text {
          margin-bottom: 1rem;
          font-family: $font-stack-primary;
          font-size: 1.25em;
          position: relative;
          width: 100%;
          z-index: 1;
        }
        .expand-icon,
        .arrow-icon {
          position: relative;
          z-index: 1;
          padding: 0.675em;
          border-radius: 100%;
          border: 2px solid $color-body-cream;

          svg {
            display: block;
          }
        }
        .arrow-icon {
          padding: 0;
          border: 0;
          @extend %nav-transition;
        }
      }
      &.category-target.fade-out {
          opacity: 0;
      }
      &.category-target.filtered {
        display: none;
      }
    }
  }
}

.no-cssgrid {
  .gallery.grid {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      height: 20em;
      width: calc(33.333% - 1em - 1px);
      opacity: 1;

      @include breakpoint(S) {
        width: calc(50% - 1em - 1px);
      }
      @include breakpoint(XS) {
        width: calc(100% - 1em);
      }
    }
  }
}

.gallery-wrapper {
  margin-bottom: 5em;
  margin-top: -15em;
}

.gallery-sidebar-edge-vert {
  position: absolute;
  top: 0;
  right: calc(100% - 10px);
  display: block;
  z-index: 2;

  @include breakpoint(S) {
    display: none;
  }
}

.gallery-sidebar-edge-horz {
  display: none;
  position: absolute;
  bottom: calc(100% - 12px);
  left: 0;
  width: 100%;
  z-index: 2;

  @include breakpoint(S) {
    display: block;
  }
}

// Gallery - Front Styles

.page-template-page-gallery-front,
.page-template-page-gallery-group,
.page-template-page-gallery-auto {
  .banner-standard {
    @include breakpoint(XS) {
      min-height: 15rem;
      height: auto;
    }

    h1,
    h2 {
    @include breakpoint(XS) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

    .banner-blanker {
      display: none;
    }
  }
  .gallery-wrapper {
    margin-top: -11em;

    @include breakpoint(XS) {
      margin-top: -9em;
    }
  }
}

.page-template-page-gallery-group {
  .banner {
    @include breakpoint(XS) {
      padding-top: 6rem;
      padding-bottom: 3rem;
    }
  }
}

.group-gallery-filter {
  display: flex;
  margin-bottom: 3rem;

  @include breakpoint(XS) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &.filtered {
    display: none;
  }
  .dropdown {
    margin: 0 1rem;
    min-width: 9rem;

    @include breakpoint(XS) {
      margin-bottom: 1.5rem;
    }
  }
  .button {
    flex-shrink: 0;
  }
}