@media print {
    body, * {
        font-family: $font-stack-system !important;
    }

    body {
        background: none;
    }

    .site-switcher-wrapper,
    .header-tel,
    .header-book,
    .nav-toggle,
    .footer-spacer,
    footer {
        display: none !important;
    }

    html.cssanimations .fade-in, html.cssanimations .fade-in-up, html.cssanimations .fade-in-down, html.cssanimations .fade-in-left, html.cssanimations .fade-in-right {
        opacity: 1 !important;
    }
}