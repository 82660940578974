.gallery-modal,
.room-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-body-cream;
    display: flex;
    @extend %transition;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 100;

        @include breakpoint(S) {
            display: flex;
        }
    }
    .gallery-modal-images {
        width: calc(100% - 4em);
        position: relative;

        @include breakpoint(S) {
            width: 100%;
            height: calc(100% - 3.625em);
        }
        .button {
            position: absolute;
            z-index: 3;
            bottom: 1.75em;
            right: 2.625em;

            @include breakpoint(S) {
                left: 5em;
                right: 5em;
                bottom: 2em;
            }
        }
        .gallery-modal-slider,
        .slick-list,
        .slick-track,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .modal-img-blanker {
            position: absolute;
            top: 0;
            left: -1px;
            z-index: 1;

            @include breakpoint(S) {
                display: none;
            }
        }
    }
    .gallery-modal-counter {
        text-align: center;
        font-weight: 700;
        margin-bottom: 1em;

        .slash {
            margin: 0 0.25em;
        }
        @include breakpoint(S) {
            margin: 0 1em;
        }
    }
    .gallery-modal-slide {
        p {
            position: absolute;
            bottom: 1.75em;
            font-weight: 700;
            left: 2.625em;
            z-index: 3;
            margin: 0;
            color: $color-body-cream;

            @include breakpoint(S) {
                position: static;
                width: 100%;
                padding: 0 2em;
                text-align: center;
                margin-bottom: 1.5rem;
            }
        }
        @include breakpoint(S) {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            background: $color-body;
            justify-content: center;
            text-align: center;

            .button {
                position: static;
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#020722+0,020722+100&0+0,0.5+100 */
            background: -moz-linear-gradient(top, rgba(2,7,34,0) 0%, rgba(2,7,34,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00020722', endColorstr='#80020722',GradientType=0 ); /* IE6-9 */
        }
    }
    .gallery-modal-sidebar {
        width: 4em;
        position: relative;
        color: $color-body-cream;
        background: $color-body;

        @include breakpoint(S) {
            width: 100%;
            height: 4.5em;
            position: fixed;
            top: 0;
            z-index: 15;
        }

        .gallery-sidebar-edge-horz {
            display: none;
        }
    }
    .gallery-modal-close {
        position: absolute;
        top: 1em;
        right: calc(50% - 1.25em);
        @extend %transition;

        svg {
            display: block;
            width: 1.75em;
            height: 1.75em;
        }
        &:hover {
            transform: rotate(180deg)
        }
        @include breakpoint(S) {
            right: 2em;
            top: auto;
            bottom: 1em;
        }
    }
    .gallery-modal-controls {
        position: absolute;
        width: 4em;
        right: 0.5em;
        bottom: 0.5em;
        z-index: 100;

        button {
            margin: 0 auto 0.5em auto;
            padding: 0.25em;
            display: block;

            svg {
                width: 1.25em;
                height: 1.25em;
            }
            @include breakpoint(S) {
                margin-bottom: 0;
                padding: 0 0.25em;
                margin-right: 0.5em;

                &.gallery-modal-prev {
                    order: -1;
                }
            }
        }
        @include breakpoint(S) {
            right: auto;
            left: 2em;
            bottom: 1em;
            width: auto;
            display: flex;
            align-items: center;
        }
    }
    &.modal-has-content {
        background: $color-green-3;

        @include breakpoint(S) {
            flex-direction: column-reverse;
            overflow: auto;

            .gallery-modal-slide img {
                margin-bottom: 0;
            }
        }
        .gallery-modal-images {
            width: calc(60% - 2em);

            @include breakpoint(S) {
                width: 100%;
                height: 20em;
                min-height: 50vh;
            }
        }
        .modal-content {
            width: calc(40% - 2em);
            padding: 2em;
            overflow: scroll;

            @include breakpoint(S) {
                width: 100%;
                padding: 2em 1em;
                overflow: visible;
            }
            li {
                &:before {
                    background: $color-dark-green;
                }
            }
        }
        .specs-grid {
            margin-bottom: 2em;

            p {
                padding-left: 1em;

                &:nth-child(odd) {
                    padding-left: 0;
                }
            }
        }
        h3 {
            margin-bottom: 0.5rem;
        }
    }
    .room-prices {
        margin-bottom: 2em;

        .price-strapline span {
            background: $color-green-3;
        }
    }
    .booking-mask-container {
        .booking-mask {
            margin-bottom: 1.5rem;
            padding-left: 0;

            .booking-form {
                flex-wrap: wrap;
                flex-direction: column;

                .input-wrap {
                    width: 100%;
                    flex-grow: 0;
                    flex-basis: auto;
                    margin: 0;
                    padding: 0.25em 1em 0 0.5em;
                    border-top: 0;
                    border-right: 0;
                    border-bottom: 1px solid rgba(#000, 0.25);
                    max-width: none;

                    label {
                        left: 0.5rem;
                    }
                }
                .button {
                    width: 100%;
                    flex-basis: auto;
                }
                .dates-wrapper {
                    flex-basis: auto;
                    min-width: 0;
                    border-right: 0;
                    padding-left: 0.5em;
                }
                .rooms-guests {
                    padding-left: 0.5em;
                    padding-right: 0;
                    border-left: 1px solid rgba(0,0,0,0.25);
                    border: 0;
                    border-top: 1px solid rgba(0,0,0,0.25);
                }
            }
        }
    }
}

.back-modal {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    font-size: 0.7778em;

    svg {
        margin-right: 0.5em;
        @extend %transition;
    }
    &:hover {
        color: $color-body;

        svg {
            transform: translateX(-0.25em);
        }
    }
}

.meeting-rooms {
    position: relative;
    z-index: 1;
}

.meeting-room {
    border-radius: 4px;
    border: 2px solid $color-body;
    overflow: hidden;
    text-align: center;
    margin: 0 1.125em;
    position: relative;
    padding-bottom: 2em;
    background: $color-body-cream;

    .meeting-room-img {
        img {
            width: 100%;
            height: 16em;

            @include breakpoint(M) {
                height: 10em;
            }
            @include breakpoint(XS) {
                height: 12rem;
            }
        }
    }
    .meeting-room-content {
        padding: 1em 1em 3em 1em;

        h4 {
            margin-bottom: 0.25rem;
        }
        .small {
            font-size: 0.777rem;
            line-height: 1.3;
        }
    }
    .meeting-room-links {
        border-top: 2px solid $color-body;
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        a {
            flex-grow: 1;
            flex-basis: 0;
            line-height: 1;
            text-align: center;
            padding: 1em 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 0.25em;
                @extend %transition;
            }
            &:first-child {
                border-right: 1px solid $color-body;
                color: $color-body;

                &:hover {
                    color: $color-mid-green;

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            &:last-child {
                border-left: 1px solid $color-body;
                color: $color-body-cream;
                background: $color-mid-green;

                &:hover {
                    background: $color-body;

                    svg {
                        transform: translateX(0.25em);
                    }
                }
            }
        }
    }
}

.room-message {
    z-index: 2;
    position: absolute;
    top: -0.5em;
    right: -1em;
    width: 5rem;
    font-weight: 700;
    font-size: 0.666em;
    color: $color-body-cream;
    padding-top: 2em;
    padding-right: 1.75em;
    line-height: 1;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
        color: $color-dark-green;
        width: 100%;
        display: block;
    }
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.25em;
        height: 7.25em;
        z-index: -1;
    }
}

.room-prices {
    border: 2px solid $color-body;
    position: relative;
    display: flex;
    padding: 0.5em 0;
    font-weight: 700;
    margin-top: 2em;

    &.has-price-strapline {
        padding-top: 1em;
    }
    p {
        text-align: center;
        margin: 0;
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 0.25em;
        font-size: 1.3333em;

        span {
            display: block;
            font-size: 0.7778rem;
        }
    }
    .price-strapline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        color: $color-dark-green;
        text-align: center;
        font-size: 0.5555em;

        span {
            display: inline-block;
            padding: 0.5em 1em;
            max-width: 90%;
            background: $color-body-cream;
            line-height: 1;
        }
    }
}

.meeting-room-carousel {
    .slick-prev {
        left: -3.5em;

        @include breakpoint(S) {
            left: -2em;
        }
        @include breakpoint(XS) {
            left: -1.75em;
        }
        &:hover {
            transform: none;
        }
    }
    .slick-next {
        right: -3.5em;

        @include breakpoint(S) {
            right: -2em;
        }
        @include breakpoint(XS) {
            right: -1.75em;
        }
        &:hover {
            transform: translateY(-50%);
        }
    }
}

.specs-grid {
    display: flex;
    flex-wrap: wrap;

    p {
        width: 50%;
        flex-grow: 1;
        line-height: 1;
        margin: 0;
        padding: 0.75em 0.25em;
        font-size: 0.7778em;
        border-top: 2px solid rgba($color-body, 0.25);

        &:nth-child(1),
        &:nth-child(2) {
            border-top: 0;
        }
        &:nth-child(odd) {
            border-right: 2px solid rgba($color-body, 0.25);
        }
        &:last-child {
            border-right: 0;
        }
    }
}
.no-cssgrid {
    .home-condensed-top {
        background: $color-body-cream;
    }
    .blob-container {
        z-index: 5!important;
    }
    .img-with-blob {
        .inner-img {
            mask: none!important;
            img {
                width: 37em;
                max-width: 40vw;
            }
        }
    }
}