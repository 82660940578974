.next-post {
    margin-bottom: 5rem;

    .cta.full-width {
        margin: 0;

        p {
            font-weight: 700;
        }
        .button {
            position: static;
            transform: none;
        }
        h2 {
            margin-bottom: 2rem;
        }
    }
}

.back {
    position: relative;
    color: $color-body;

    svg {
        position: absolute;
        left: -1.75em;
        top: 50%;
        transform: translateY(-50%);
        @extend %transition;
    }
    &:hover {
        color: $color-dark-green;

        svg {
            transform: translate(-0.25em, -50%);
        }
    }
}

.back-p {
    @include breakpoint(S) {
        text-align: center;
    }
}

.inner-content {
    max-width: 45em;

    @include breakpoint(S) {
        max-width: 40em;
        margin: 0 auto;
    }
    > p:first-child {
        font-size: 1.25rem;
        margin-bottom: 2em;
    }
}

.more-offers {
    .cta {
        max-width: 50%;

        @include breakpoint(S) {
            max-width: none;
        }
    }
    .base-ctas {
        justify-content: center;
    }
    .ctas-texture {
        mix-blend-mode: multiply;
    }
}

.woocommerce {
    &.single-product {
        .woocommerce-product-gallery,
        .product_meta,
        #sidebar {
            display: none;
        }
    }
}