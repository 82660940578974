// Offers Listing Page template
.page-template-page-offers-listing {
    .offer-main-content {
        > p {
            display: none;

            &:first-child {
                display: block;
            }
        }
        

    }
}

// Single Offer Styles
.single-offers {
    .content-with-sidebar {
        padding-bottom: 0;
        
        .sidebar {
            margin-top: 0;
            padding-top: 0;
        }
    }
}