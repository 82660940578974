.page-content-wrapper {
    background: $color-dark-green;
    padding-left: 3.5em;
    padding-right: 3.5em;

    @include breakpoint(S) {
        padding-left: 0;
        padding-right: 0;
    }
}

.page-content {
    text-align: center;
    max-width: 62em;
    margin: 8em auto 0 auto;
    padding-bottom: 8em;
    position: relative;
    overflow: hidden;

    .page-content-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100em;
        z-index: 1;

        @include breakpoint(S) {
            height: 85em;
        }
        @include breakpoint(XS) {
            height: 37em;
        }
    }
    .page-content-bottom {
        position: absolute;
        left: 0;
        top: 90em;
        bottom: 0;
        width: 100%;
        transform: scale(-1) translateY(10em);
        z-index: 1;

        @include breakpoint(S) {
            top: 70em;
        }
        @include breakpoint(XS) {
            top: 30em;
        }
    }
    .page-content-inner {
        position: relative;
        z-index: 2;
        padding: 7em 10em;

        hr {
            margin-bottom: 4em;
        }
        @include breakpoint(M) {
            padding: 10em 6em 7em 6em;
        }
        @include breakpoint(XS) {
            padding: 6em 3em 7em 3em;
        }
    }
    ul {
        li {
            padding-left: 0;
            border-bottom: 2px solid rgba(33,87,50,0.25);
            padding-bottom: 0.75em;

            &:before {
                display: none;
            }
            &:last-child {
                border: 0;
            }
        }
    }
    ol {
        li {
            padding-left: 0;
            padding-top: 2em;
            padding-bottom: 1em;

            &:before {
                width: 2rem;
                height: 2rem;
                left: calc(50% - 1em);
            }
        }
    }
}

.base-ctas {
    display: flex;
    padding-bottom: 5em;
    margin-left: -0.75em;
    margin-right: -0.75em;
    margin-top: -5.5em;
    position: relative;
    z-index: 3;

    @include breakpoint(S) {
        flex-direction: column;

        .cta {
            width: calc(100% - 1.5em);
            min-height: 18em;
        }
    }
    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;

        .cta {
            width: 100%;
        }
    }
}

.base-ctas-wrapper {
    position: relative;
    width: 100%;
    margin-top: -5em;

    .ctas-blanker {
        width: 100%;
        overflow: hidden;

        svg {
            width: calc(100% + 10px);
            height: auto;
            display: block;
            transform: translateX(-5px);
            position: relative;
            z-index: 1;
        }
    }
    .ctas-texture {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;

        svg {
            position: absolute;
            width: 150%;
            height: 100%;
            top: 1em;
            left: -25%;

            @include breakpoint(S) {
                width: 500%;
            }
        }
    }
}

.content-with-sidebar {
    margin-bottom: 4rem;
    padding-bottom: 8rem;

    .sidebar {
        width: 20em;
        float: left;
        margin-top: -6em;
        padding-top: 2em;
        z-index: 2;

        @include breakpoint(S) {
            float: none;
            margin: -12em auto 8em auto;
            min-width: 100%;
            width: 100%;
            z-index: 2;
        }
        @include breakpoint(XS) {
            margin-bottom: 2em;
        }
    }
    .page-sidebar-content {
        width: calc(90% - 20em);
        float: right;

        @include breakpoint(S) {
            width: 100%;
        }
    }
    &.pb-banner {
        padding-bottom: 0;
        margin-bottom: 2em;
    }
}

.sidebar {
    .sidebar-widget {
        padding: 1.5em;

        &:last-child {
            border-bottom: 0;
        }
        &.share {
            .share-links {
                margin: 0;
                justify-content: flex-start;

                a {
                    opacity: 0.5;

                    &:first-child {
                        margin-left: 0;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        &.newsletter {
            p {
                font-size: 0.875em;
            }
        }
    }
}

.subpage-lockup {
    position: relative;
    min-height: 20em;

    @include breakpoint(S) {
        text-align: center;
    }

    .subpage-lockup-blob {

        svg {
            position: absolute;
            top: -15%;
            left: -15%;
            width: 130%;
            height: auto;
            z-index: -1;

            @include breakpoint(S) {
                top: 0;
                left: 0;
                width: 100%;
                top: -3em;
                left: 50%;
                width: 28em;
                transform: translateX(-50%);
            }
            @include breakpoint(XS) {
                width: 100%;
            }
        }
    }
    &.subpage-lockup-1 {
        padding-left: 1em;
        padding-top: 4em;

        @include breakpoint(S) {
            padding-top: 7em;
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(XS) {
            padding-top: 5em;
            padding-left: 1.5em;
            padding-right: 5em;
        }
    }
    &.subpage-lockup-2 {
        padding-left: 5em;
        padding-top: 4em;

        @include breakpoint(S) {
            padding-top: 6em;
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(XS) {
            padding-top: 4.5em;
            padding-left: 5em;
            padding-right: 0.5em;
        }
    }
    &.subpage-lockup-3 {
        padding-left: 4em;
        padding-top: 3em;

        @include breakpoint(S) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 5em;
        }
    }
    &.subpage-lockup-4 {
        padding-left: 4em;
        padding-top: 3em;

        @include breakpoint(S) {
            padding-top: 4em;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.subpage-lockup-5 {
        padding-top: 4em;

        @include breakpoint(S) {
            padding-top: 6em;
            padding-right: 2em;
        }
        @include breakpoint(S) {
            padding-top: 4em;
        }
    }
    &.subpage-lockup-6 {
        padding-top: 3em;

        @include breakpoint(S) {
            padding-top: 4em;
            padding-left: 2em;
            padding-right: 5em;
        }
        @include breakpoint(XS) {
            padding-top: 2em;
            padding-right: 4em;
            padding-left: 2em;
        }
    }
    &.subpage-lockup-7 {
        padding-top: 2em;
        padding-left: 1em;

        h1,
        h2,
        h3,
        h4,
        p {
            color: $color-green-2;
        }
        @include breakpoint(S) {
            padding-top: 6em;
            padding-left: 4em;
            padding-right: 5em;
        }
        @include breakpoint(XS) {
            padding-top: 3em;
            padding-left: 3em;
            padding-right: 3em;
        }
    }

    &.no-images {
        h1 {
            font-size: 2.3889em;
            font-weight: 700;
            line-height: 3rem;
            margin-bottom: 1.5rem;
        }
    }

}

.location-content-wrapper {
    min-height: 8em;
    padding-top: 4em;

    .location-blocks {
        float: left;
        width: calc(100% - 5em);

        @include breakpoint(S) {
            width: 100%;
        }
        .inpage-nav {
            margin: 0;
        }
    }
}

.location-content {
    display: none;

    &.default-content {
        @include breakpoint(XS) {
            .img {
                order: 1;
                margin-bottom: 8em;
            }
            .content {
                margin-bottom: 5em;

                p {
                    display: none;
                }
            }
        }
    }

    &.active {
        display: block;
    }
    @include breakpoint(S) {
        .builder-elements {
            padding: 0;
        }
    }
    @include breakpoint(XS) {
        &.default-content {
            .row {
                margin: 0;
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }
    }
}

.location-switcher {
    float: right;
    width: 5em;
    padding-top: 2.5em;
    overflow: hidden;

    @include breakpoint(S) {
        display: none;
    }
    a {
        color: $color-body-cream;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1em;
        width: 4em;
        height: 7em;
        float: right;
        clear: both;

        .close {
            display: none;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 0.675rem;
            height: 0.675rem;
            border-radius: 100%;
            font-size: 0.5em;
            color: $color-dark-green;
            background: $color-body-cream;
            margin-left: 0.875em;
        }
        #texture {
            @extend %transition;
            opacity: 0;
        }
        &.active {
            padding-right: 0.5em;

            .close {
                display: flex;
            }
            #texture {
                opacity: 1;
            }
        }
        &.inactive {
            transform: scale(0.75);
        }
        svg {
            position: absolute;
            width: 7em;
            height: 7em;
            top: 0;
            right: 0;
            z-index: -1;
            @extend %transition;
        }
        &:nth-child(1) {
            svg {
                transform: rotate(62deg);
                right: -2em;
            }
        }
        &:nth-child(2) {
            svg {
                transform: rotate(52deg);
                right: -3em;
            }
        }
        &:nth-child(3) {
            svg {
                transform: rotate(159deg);
                right: -2.5em;
            }

            .close {
                background: $color-body;
                color: #fff;
            }
            &.active {
                color: $color-body;
            }
        }
    }
}

.enquiries-wrap {
    text-align: center;
    margin-bottom: 8em;

    @include breakpoint(XS) {
        margin-bottom: 5em;
    }
}

.enquiries-section {
    display: flex;
    flex-wrap: wrap;

    .enquiry-block {
        position: relative;
        width: 25%;
        padding: 2em 1em;

        @include breakpoint(S) {
            width: 50%;
            padding: 3em 1em;
        }
        @include breakpoint(XS) {
            width: 100%;
        }
        h4 {
            margin: 0;
        }
        p {
            font-size: 0.777em;
            margin-bottom: 0.5rem;
            line-height: 1.5em;

            &:last-child {
                margin: 0;
            }
        }
        .blob {
            position: absolute !important;
            top: 0;
            left: 10%;
            width: 80%;
            height: 80%;
            z-index: -1;

            @include breakpoint(S) {
                left: 15%;
                width: 60%;
                height: 60%;
            }
            svg {
                width: 100%;
                height: auto;
                @include breakpoint(S) {
                    width: 15em;
                }
            }
        }
    }
}

.contact-form {
    background: #ffd5cd;
    padding: 3em 0;
    margin-bottom: 8em;
    position: relative;

    .contact-form-top {
        position: absolute;
        bottom: calc(100% - 2px);
        left: 0;
        width: 100%;
        overflow: hidden;

        svg {
            width: 100%;
            height: auto;
            min-width: 30em;
            display: block;
        }
    }
    .contact-form-bottom {
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        width: 100%;
        overflow: hidden;

        svg {
            width: 100%;
            height: auto;
            min-width: 30em;
            display: block;
        }
    }
    .titles {
        text-align: center;
        max-width: 42rem;
        margin: 0 auto;
        @include breakpoint(XS) {
            text-align: left;
        }
    }
    .form-blob {
        overflow: hidden;

        .container {
            display: flex;

            @include breakpoint(XS) {
                display: block;
            }
            .form-wrapper {
                width: 50%;

                @include breakpoint(XS) {
                    width: 100%;
                }
            }
            .blob-wrapper {
                width: 50%;

                @include breakpoint(XS) {
                    width: 100%;
                }
            }
        }
    }
    .blob-text {
        text-align: center;
        display: block;
        position: relative;
        color: $color-body;
        width: 20em;
        padding: 2em;
        margin: 0 auto;

        h3 {
            margin: 1rem 0;
        }
        p {
            font-weight: 300;
            margin-bottom: 0.5rem;
        }
        .form-blob-1 {
            position: absolute;
            top: -20%;
            left: -10%;
            width: 120%;
            height: 120%;
            z-index: -1;
            @extend %nav-transition;

            @include breakpoint(XS) {
                top: -15%;
                left: -5%;
                width: 110%;
                height: 110%;
            }
        }
        .form-blob-2 {
            position: absolute;
            top: -15%;
            left: 30%;
            width: 130%;
            height: 130%;
            z-index: -2;

            @include breakpoint(XS) {
                top: -40%;
            }
        }
        .arrow {
            @extend %nav-transition;
        }
        &:hover {
            .arrow {
                transform: translateX(0.25em);
            }
            .form-blob-1 {
                top: -25%;
                left: -15%;
                width: 130%;
                height: 130%;
            }
        }
    }
    .blob-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(S) {
            padding-left: 3em;
        }
        @include breakpoint(XS) {
            padding-left: 0;
            padding-top: 3em;
        }
    }
}

.directions-wrap {
    margin-bottom: 8em;

    @include breakpoint(XS) {
        overflow: hidden;
    }
}

.direction-wrap {
    display: flex;
    align-items: center;

    @include breakpoint(XS) {
        display: block;
    }
    .direction-lockup {
        padding-right: 5em;
        width: 40%;
        text-align: center;

        @include breakpoint(S) {
            width: 50%;
        }
        @include breakpoint(XS) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 5em;
        }
    }
    .directions-list {
        width: 60%;

        @include breakpoint(S) {
            width: 50%;
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .direction-method {
        border-bottom: 2px solid rgba($color-body, 0.25);
        display: flex;
        align-items: baseline;
        padding: 0.5em 0;
        position: relative;

        @include breakpoint(XS) {
            display: block;
        }
        .button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        p {
            margin: 0;

            &:first-child {
                width: 10em;
                padding-right: 2em;
                font-weight: 700;
                flex-grow: 0;
                flex-shrink: 0;

                @include breakpoint(XS) {
                    width: 100%;
                }
            }
            &:nth-child(2) {
                font-size: 0.777em;
            }
            &.has-link {
                padding-right: 4.5em;
            }
        }
    }
    .direction-icon {
        width: 7em;
        height: 7em;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        svg {
            width: 4.5em;
            height: 4.5em;
        }
        .icon-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        .icon {
            path,
            polyline {
                stroke: $color-body;
            }
        }
    }
    .direction-lockup-inner {
        padding: 0 2em;
        position: relative;
    }
    .direction-bg {
        position: absolute;
        top: -10%;
        left: -15%;
        width: 135%;
        height: 135%;
        z-index: -2;
    }
}

.tab-1 {
    .direction-wrap .direction-icon .icon {
        path,
        polyline {
            stroke: #fff;
        }
    }
}

.blob-with-text {
    position: relative;
    text-align: center;
    padding: 0 1em;

    p {
        font-size: 0.777em;
        line-height: 1.5em;

        &:last-child {
            margin: 0;
        }
    }
    > svg {
        position: absolute;
        top: -20%;
        left: -25%;
        width: 150%;
        height: 150%;
        z-index: -1;

        @include breakpoint(XS) {
            left: 0;
            width: 100%;
        }
    }
}

.blob-modal,
.fancybox-slide .blob-modal {
    text-align: center;
    width: 70vw;
    padding: 5em 8em 2em 8em;
    min-height: calc(100vh - 10em);
    margin-top: 10em;
    background: url(/wp-content/themes/qbic/images/modal-bg.svg);
    background-size: 100%;
    margin-bottom: 0;

    @include breakpoint(S) {
        width: 95vw;
        min-height: calc(100vh - 2em);
        margin-top: 2em;
        padding: 5em 5em 2em 5em;
    }
    @include breakpoint(XS) {
        padding: 5em 3em 2em 3em;
    }
    &.left-align {
        text-align: left;
    }
}

// Contact Page template
.page-template-page-contact {
    .no-banner-wrapper {
        padding-top: 6em;
    }
}

// Rooms Page template
.page-template-page-rooms {
    @include breakpoint(S) {
        h1 {
            text-align: center;
        }
    }
}

.menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    visibility: hidden;
    opacity: 0;
    @extend %transition;
    background: $color-body-cream;

    .menu-modal-inner {
        position: absolute;
        top: 2em;
        left: 2em;
        right: 2em;
        bottom: 2em;
        border: 2px solid $color-body;
    }
    .menu-modal-header,
    .menu-modal-footer {
        position: absolute;
        left: 0;
        width: 100%;
        height: 6em;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            margin: 0;
            font-size: 2em;
            line-height: 1;
        }
        @include breakpoint(XS) {
            height: 4em;

            h2 {
                font-size: 1.125em;
            }
        }
    }
    .menu-modal-header {
        top: 0;
        border-bottom: 2px solid $color-body;
    }
    .menu-modal-footer {
        bottom: 0;
        border-top: 2px solid $color-body;
        cursor: pointer;

        a {
            color: $color-body;
        }
    }
    .menu-modal-content {
        position: absolute;
        left: 0;
        width: 100%;
        top: 6em;
        bottom: 6em;
        overflow: scroll;
        padding: 2em 3em;

        @include breakpoint(XS) {
            padding: 1.5em 2em;
            top: 4em;
            bottom: 4em;
        }
        p {
            font-size: 0.875em;
            line-height: 1.5em;
        }
    }
    &.open {
        z-index: 100;
        visibility: visible;
        opacity: 1;
    }
}

.menus-tabs {
    text-align: center;
    padding-bottom: 2em;

    a {
        text-transform: uppercase;
        margin: 0 0.75em;
        font-size: 1.25em;
        color: $color-body;

        &.active {
            text-decoration: underline;
        }
    }
}

.menu-tab {
    display: none;
    max-width: 42em;
    margin: 0 auto;

    &.active {
        display: block;
    }
}

.calendar-blob {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.room-calendar-days {
    margin: 1rem 0;
    display: flex;
    justify-content: center;

    @include breakpoint(XS) {
        display: none;
    }

    .day {
        text-align: center;
        margin: 0 0.5rem;
        width: calc(14% - 1rem);
        font-weight: 700;
        font-size: 1.25rem;
    }
}

.room-calendar-title {
    text-align: center;
    border-bottom: 2px solid $color-body;

    width: 85%;
    margin: 0 auto;

    h2 {
        margin-bottom: 1rem;
        color: $color-dark-green;
        font-size: 1.625rem;
    }

    @include breakpoint(XS) {
        width: 87%;

        margin-bottom: 1rem;
    }
}

.room-calendar-container {
    display: flex;
    justify-content: center;

    padding-top: 8rem;

    @include breakpoint(ML) {
        flex-direction: column;

        padding-top: 2rem;
    }

    @include breakpoint(XS) {
        padding-top: 0rem;
    }
}

.room-calendar-content {
    width: 40%;

    padding-top: 4rem;
    padding-right: 1rem;

    @include breakpoint(ML) {
        width: 100%;

        margin-right: 0;

        padding-left: 1rem;

        text-align: center;
    }

    h1 {
        font-size: 3rem;
        margin-bottom: 0.5rem;

        color: $color-dark-green;

        @include breakpoint(S) {
            margin-bottom: 1rem;
        }

        @include breakpoint(XS) {
            font-size: 2.5rem;
        }
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        padding-right: 3rem;

        @include breakpoint(S) {
            padding-right: 1.5rem;
        }
    }
}

.room-calendar-inner {
    width: 60%;

    @include breakpoint(ML) {
        width: 100%;
    }
}

.room-calendar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5rem;

    .room {
        border-radius: 0.5rem;
        margin: 0.5rem;
        width: calc(14% - 1rem);
    }
    .empty-day {
        border-radius: 0.5rem;
        margin: 0.5rem;
        width: calc(14% - 1rem);
        border: 2px solid $color-body;

        @include breakpoint(XS) {
            display: none;
        }
    }
    .date-text {
        display: none;

        margin-bottom: -0.5rem;

        padding-top: 0.5rem;

        color: #fff;

        font-size: 0.75rem;

        @include breakpoint(XS) {
            display: block;
        }
    }
    .day {
        background: $color-body;
        border-radius: 0.5rem;
        margin: 0.5rem;
        width: calc(14% - 1rem);
        text-align: center;
        overflow: hidden;

        @include breakpoint(XS) {
            width: 20%;

            margin: 0.5rem 0.25rem;
        }
        
        .date {
            font-weight: 700;
            font-size: 2.25rem;
            color: $color-body-cream;
            padding: 1rem 0;

            @include breakpoint(L) {
                padding: 0;
            }

            @include breakpoint(ML) {
                padding: 1rem 0;
            }

            @include breakpoint(XS) {
                padding: 0;
            }
        }
        a,
        span {
            font-weight: 700;
            color: $color-body-cream;
            display: block;
            background: $color-dark-green;
            font-size: 0.5625rem;
            padding: 0.5rem;
            border-top: 2px solid $color-body;

            &:hover {
                background: $color-green-2;
            }
        }
        span {
            border-top: 2px solid $color-body-cream;
            background: none;

            &:hover {
                background: none;
            }
        }
        &.unavailable {
            opacity: 0.25;
        }
    }
}

.room-calendar-banner {
    text-align: center;
    padding-top: 7.5rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    overflow: hidden;
    position: relative;

    .room-calendar-banner-inner {
        margin: 0 auto;
        max-width: 45rem;
        padding: 0 1.5rem;
    }
    h1 {
        color: $color-dark-green;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 125vw;
        height: 100vh;
        z-index: -1;
        border-radius: 100%;
        background: $color-pink-3;
    }
}

// WooCommerce Styling

.woocommerce-checkout .page-content .page-content-inner h1,
.woocommerce-checkout .page-content .page-content-inner hr {
    display: none;
}

.woocommerce-form-coupon-toggle .woocommerce-info {
    display: none;
}

.woocommerce-billing-fields h3 {
    display: none;
}

.woocommerce-additional-fields {
    display: flex;
    flex-wrap: wrap;
    
    h3 {
        display: none;
    }
}

#order_review_heading {
    display: none;
}

.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table {
    display: none;
}

.woocommerce-billing-fields__field-wrapper,
.testers-twin-room {
    display: flex;
    flex-wrap: wrap;

    text-align: left;

    p {
        width: 25rem;
        margin-left: 2rem;

        label {
            font-weight: 700;
        }

        @include breakpoint(XS) {
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }
}

.testers-twin-room {
    label {
        cursor: pointer;
    }
    input[type=checkbox] {
        position: static;
        opacity: 1;
        margin-right: 0.5rem;
    }
}

.woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper input {
    padding: 1rem;

    border: 2px solid #215732;
    border-radius: 4px;
}

.woocommerce-checkout-payment {
    margin-bottom: 3rem;
}

.woocommerce-checkout-payment .place-order {
    margin-top: 3.5rem;

    background-color: $color-body-cream;
    text-align: center;
}

.woocommerce-checkout-payment .wc_payment_methods {
    display: none !important;
}

.woocommerce-checkout-review-order .woocommerce-checkout-payment .place-order button {
    font-size: 1.75rem;

    line-height: 1.875rem;

    padding: 1rem 4.3125rem;
}

.blockUI {
    background-color: $color-body-cream !important;
}

// Custom Checkout Page

.checkout-page {

    position: relative;

    .booking-bar {
        display: none;
    }

    &__heading {
        width: 80%;
        height: 26.875rem;

        margin: 0 auto;
    }

    &__shape {
        svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            width: 100%;

            @include breakpoint(XS) {
                top: -10rem;
            }
        }
    }

    &__intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
    }

    &__title {
        color: $color-dark-green;

        font-size: 4rem;

        line-height: 4.375rem;
    }

    &__selection {
        font-size: 2.6875rem;

        line-height: 3.375rem;
    }

    &__strapline {
        font-size: 1.25rem;

        line-height: 1.875rem;
    }
    
    &__content {
        width: 55.625rem;

        margin: 0 auto;

        @include breakpoint(XS) {
            width: 100%;
        }

        &--testers {
            width: 100%;

            form {
                width: 55.625rem;
                margin: 0 auto;

                @include breakpoint(XS) {
                    width: 100%;
                }
            }

            @include breakpoint(S) {
                width: 95%;
            }
        }
    }
}

.checkout-review {

    &__shape {
        display: none;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            width: 100%;

            @include breakpoint(XS) {
                top: -10rem;
            }
        }
    }

    &__intro {
        display: none;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
    }

    &__title {
        color: $color-dark-green;

        font-size: 4rem;

        line-height: 4.375rem;
    }

    &__ref {
        font-size: 2.6875rem;

        line-height: 3.375rem;

        @include breakpoint(XS) {
            text-align: center;
        }
    }

    &__content {

        max-width: 39rem;

        font-size: 1.25rem;

        line-height: 1.875rem;

        text-align: center;
    }
}

// Order Review
.woocommerce-order .woocommerce-order-details,
.woocommerce-order .woocommerce-customer-details {
    display: none !important;

    .woocommerce-column__title {
        display: none !important;
    }

    address {
        display: none !important;
    }
}

.woocommerce-order-received .checkout-review__intro {
    display: flex;
}

.woocommerce-order-received .checkout-review__shape {
    display: block;
}

.woocommerce-order-received .checkout-page__intro {
    display: none;
}

.woocommerce-order-received .checkout-page__shape {
    display: none;
}

.woocommerce-order-received .booking-bar {
    display: none;
}

.qbic-experience {
    color: $color-dark-green;
}

.euroroom {
    margin-bottom: 0;
    padding-bottom: 0;

    .img {
        svg {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
            animation: rotating 20s linear infinite;

            @include breakpoint(XS) {
                width: 300px;
                height: 300px;
            }
        }
    }
    &.img-content.text-image .img {
        @include breakpoint(XS) {
            margin-bottom: 3rem;
        }
    }
}

.signup-form {
    max-width: 58rem;
    margin: 0 auto;
    padding: 2rem;
    margin-top: 2rem;
    background-image: url(/wp-content/themes/qbic/images/signup-bg.svg);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    .submit-button {
        @include breakpoint(XS) {
            text-align: center;
        }
    }
    .form-row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(XS) {
            display: block;
        }
        &.top-aligned {
            align-items: flex-start;
        }
        &.wrap {
            flex-wrap: wrap;
        }
    }
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .opt-in {
        @include breakpoint(XS) {
            margin-bottom: 1rem;
        }
    }
    label {
        @include breakpoint(XS) {
            font-size: 0.875rem;
        }
    }
    .input-wrap {
        margin-right: 1.125rem;
        flex-grow: 1;

        @include breakpoint(XS) {
            clear: both;
        }
        &.half {
            width: calc(50% - 1.5rem);

            @include breakpoint(XS) {
                width: 100%;
                margin-right: 0;
            }
        }
        &.third {
            width: calc(33.333% - 1.5rem);

            @include breakpoint(XS) {
                width: 100%;
                margin-right: 0;
            }
        }
        &.two-thirds {
            width: calc(66.666% - 1.5rem);

            @include breakpoint(XS) {
                width: 100%;
                margin-right: 0;
            }
        }
        &.long {
            min-width: 12rem;
        }
    }
    textarea {
        min-height: 6rem;
    }
    .checkbox {
        label {
            @include breakpoint(XS) {
                &:before {
                    top: 0;
                }
                &:after {
                    top: 6px;
                }
            }
        }
    }
}

// hide stuff on the 100 testers subsite
.site-13 {
    .site-switcher-wrapper,
    .nav-toggle,
    footer,
    .booking-bar,
    .mobile-booking-mask {
        display: none !important;
    }
}

.ibe-wrapper {
    h2,
    p {
        color: $color-dark-green;
    }
    .container {
        min-height: 30rem;
    }
}

.page-template-page-select-dates .mob-booking {
    display: none;
}

.green-box {
    padding: 2rem;
    border-radius: 4px;
    background: $color-green-3;

    *:last-child {
        margin-bottom: 0;
    }
    p {
        font-size: 0.875rem;
        line-height: 1.3;
        color: $color-body;
    }
    li {
        font-weight: 700;
        line-height: 1.3;
        font-size: 0.875rem;

        &:before {
            color: $color-dark-green;
            font-size: 0.875rem;
            top: -3px;
        }
    }
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    .box {
        width: calc(50% - 2.5rem);
        background: $color-green-3;
        margin: 1.25rem;
        border-radius: 0.5rem;
        padding: 2rem;

        h3 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 0.5rem;
            color: $color-body;

            &:last-child {
                margin: 0;
            }
        }
        @include breakpoint(XS) {
            width: calc(100% - 2.5rem);
        }
    }
}

// condensed page for motley manchester
.site-15 {
    .page-id-4 {
        footer.location-site,
        .mobile-booking-mask,
        .booking-bar {
            display: none !important;
        }
    }
}