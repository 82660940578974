%transition {
  transition: all 0.3s ease-out;
}

%link-transition {
  transition: transform 0.5s cubic-bezier(0, 0, 0.21, 0.99), opacity 0.3s cubic-bezier(0, 0, 0.21, 0.99);
}

%button-transition {
  transition: all 0.5s cubic-bezier(0, 0, 0.21, 0.99);
}

%submenu-transition {
  transition: all 1s cubic-bezier(0, 0, 0.21, 0.99);
}

%nav-transition {
  transition: all 1s cubic-bezier(0.59, 0, 0.06, 1);
}

@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}
