.room-showcase {
    margin-bottom: 10em;
    display: flex;
    position: relative;
    width: calc(100% - 6em);
  
    @include breakpoint(S) {
        width: calc(100% - 1.5em);
    }
    @include breakpoint(XS) {
        display: block;
        margin: 0 1em 1em 1em;
        width: calc(100% - 2em);
        background: $color-body-cream;
        box-shadow: -2px -2px 16px rgba(2, 7, 24, 0.1);
    }
    .room-img {
        width: 60%;
        min-height: 32em;
        position: relative;

        .gallery-modal-trigger {
            position: absolute;
            padding: 0.5em;
            bottom: 0;
            z-index: 1;
            background: rgba($color-body, 0.5);

            &:hover {
                background: $color-dark-green;
            }
            svg {
                display: block;
            }
            @include breakpoint(XS) {
                bottom: auto;
                top: 0;
                left: auto;
                right: 0;
            }
        }
        @include breakpoint(L) {
            min-height: 25em;
        }
        @include breakpoint(M) {
            min-height: 20em;
        }
        @include breakpoint(S) {
            width: 50%;
        }
        @include breakpoint(XS) {
            width: 100%;
            min-height: 15em;
        }
        .image-carousel,
        .slick-list,
        .slick-track,
        .image-carousel img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    p:last-child {
        margin: 0;
    }
    .room-details {
        position: relative;
        width: 40%;
        padding: 3.25em 4.5em;
        background: $color-body-cream;
        box-shadow: -2px -2px 16px rgba(2, 7, 24, 0.1);
        transform: translateY(5em);
        z-index: 1;
  
        @include breakpoint(S) {
            width: 50%;
            padding: 3em;
        }
        @include breakpoint(XS) {
            width: 100%;
            padding: 5em 1.5em 2em 1.5em;
            transform: none;
            background: none;
            box-shadow: none;
        }
    }
    .from-price {
        position: absolute;
        top: 0;
        left: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        color: $color-body-cream;
        transform: translate(-50%, -50%);
        width: 6.75em;
        height: 6.75em;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(XS) {
            left: auto;
            right: 1em;
            top: 0;
            transform: translateY(-50%);
        }
        span {
            font-size: 0.75em;
            line-height: 1;
        }
        p {
            margin: 0;
            font-weight: 700;
            font-size: 1.75em;
            line-height: 1;
        }
    }
    .icon-list li {
        @include breakpoint(M) {
            width: calc(100% - 0.5em);
        }
        @include breakpoint(S) {
            width: calc(50% - 0.5em);
        }
    }
    .room-content {
        margin-bottom: 1em;

        @include breakpoint(XS) {
            display: none;
            padding-left: 1em;
            border-left: 2px solid rgba($color-body, 0.15);
        }
    }
    .room-content-toggle {
        display: none;
        margin-bottom: 1em;
        font-weight: 700;
        color: rgba($color-body, 0.25);

        &.active {
            color: $color-mid-green;
        }
        @include breakpoint(XS) {
            display: block;
        }
    }
    &:nth-child(even) {
        margin-left: 6em;

        @include breakpoint(S) {
            margin-left: 1.5em;
        }
        @include breakpoint(XS) {
            margin: 0 1em 1em 1em;
            width: calc(100% - 2em);
        }
        .room-img {
            order: 2;

            .gallery-modal-trigger {
                left: 0;

                @include breakpoint(XS) {
                    left: auto;
                }
            }
        }
        .room-details {
            order: 1;
        }
        .from-price {
            left: auto;
            right: 0;
            transform: translate(50%, -50%);

            @include breakpoint(XS) {
                left: auto;
                right: 1em;
                top: 0;
                transform: translateY(-50%);
            }
        }
    }
    &:nth-child(odd) {
        margin-right: 6em;
        
        @include breakpoint(S) {
            margin-right: 1.5em;
        }
        .room-img {
            .gallery-modal-trigger {
                right: 0;
            }
        }
        .title-line {
            h2 {
  
                &:after {
                    right: calc(100% + 0.25em);
                    left: auto;
                }
            }
        }
    }
    @include breakpoint(XS) {
        .slick-dots {
            text-align: left;
            width: auto;
            left: 1em;
        }
    }
}

.rooms-list.grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4.25em;
    margin-bottom: 5em;

    @include breakpoint(M) {
        padding: 0 1.75em;
    }
    @include breakpoint(S) {
        padding: 0 1em;
    }
    @include breakpoint(XS) {
        padding: 0;
    }
    .room-showcase {
        width: calc(50% - 1.5em);
        flex-direction: column;
        margin: 0.75em;
        background: $color-body-cream;
        box-shadow: -2px -2px 16px rgba(2, 7, 24, 0.1);

        @include breakpoint(XS) {
            width: calc(100% - 2em);
            margin: 0 1em 1em 1em;
        }
        &:nth-child(odd) {
            margin: 0.75em;

            @include breakpoint(XS) {
                margin: 0 1em 1.5em 1em;
            }
        }
        .room-img,
        .room-details {
            width: 100%;

            .gallery-modal-trigger {
                bottom: auto;
                left: auto;
                top: 0;
                right: 0;
            }
        }
        .from-price {
            left: auto;
            right: 2.5em;
            transform: translateY(-50%);

            @include breakpoint(S) {
                right: 1em;
            }
        }
        &:nth-child(even) .room-img {
            order: unset;
        }
        .room-details {
            padding: 2.5em;
            transform: none;
            box-shadow: none;

            @include breakpoint(M) {
                padding-top: 5em;
            }
            @include breakpoint(S) {
                padding: 5em 1.5em 2em 1.5em;
            }
            h2 {
                padding-right: 2em;

                @include breakpoint(M) {
                    padding-right: 0;
                }
            }
        }
        .icon-list li {
            width: calc(33% - 0.5em);

            @include breakpoint(M) {
                width: calc(50% - 0.5em);
            }
        }
        .slick-dots {
            bottom: 1em;
            left: 2em;
            text-align: left;
            width: auto;

            @include breakpoint(M) {
                bottom: -3em;
                z-index: 3;
            }
            @include breakpoint(S) {
                width: auto;
                left: 1em;
            }
        }
    }
}

.rooms-list {
    display: flex;
    margin-left: -1.125em;
    margin-right: -1.125em;

    @include breakpoint(XS) {
        display: block;
        margin-left: -1.75rem;
        margin-right: -1.75rem;
    }
    .meeting-room .meeting-room-content {
        text-align: left;

        @include breakpoint(XS) {
            min-height: 12rem;
        }
        h6 {
            margin-bottom: 0.25em;
        }
    }
    .meeting-room {
        flex-grow: 1;
        flex-basis: 0;

        @include breakpoint(XS) {
            margin-bottom: 1em;
        }
    }
    &.js-mob-carousel {
        @include breakpoint(XS) {
            padding-bottom: 5rem;

            .meeting-room {
                width: 16rem;
                margin: 0 0.75em;
            }
            .slick-arrow {
                top: auto;
                bottom: 0;
                transform: none;

                &.slick-prev {
                    left: calc(50% - 4rem);
                }
                &.slick-next {
                    right: calc(50% - 4rem);
                }
            }
        }
    }
}

.room-feats {
    font-size: 0.777em;
    margin-bottom: 0.5rem;

    span {
        display: block;
        line-height: 1.3;
        margin-bottom: 1em;
    }
}

.rooms-wrapper {
    margin-bottom: 5em;

    > .container {
        display: flex;

        @include breakpoint(S) {
            display: block;
        }
        .rooms-left {
            width: 100%;

            @include breakpoint(S) {
                width: 100%;
            }
            @include breakpoint(XS) {
                margin-bottom: 5em;
            }

            .booking-mask {
                position: relative;

                .input-wrap {
                    @media (max-width: 1300px) {
                        font-size: 0.85em;
                    }
                }

                .dates-wrapper {
                    @media (max-width: 1075px) {
                        min-width: 13em;
                    }
                }

            @media (min-width: 768px) and (max-width: 880px) {
                padding-bottom: 2.6em;

                .button {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }
            }

            }
        }
        .rooms-right {
            width: 25%;
            padding-left: 1em;
            text-align: center;

            @include breakpoint(S) {
                width: 100%;
                padding-left: 0;
            }
            h4 {
                margin-bottom: 1rem;
            }
        }
    }
    .stick-in {
        @include breakpoint(S) {
            position: relative;
            display: flex;
        }
        @include breakpoint(XS) {
            display: block;
        }
    }
    .booking-mask-container {
        margin-bottom: 2em;
    }
    .blob-with-text {
        margin-bottom: 5em;
    }
    .trustyou {
        iframe {
            overflow: hidden;
            width: 200px;
            height: 60px;
            margin: 0 auto;
        }
    }
    .tripadvisor {
        margin-bottom: 2em;

        p {
            font-size: 0.5556em;
        }
    }
}

.ta-rating {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin: 0 0.25em;
    }
}