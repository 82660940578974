// Colors
$color-body: #215732;
$color-body-cream: #FFF5F0;
$color-pink: #fad4cd;
$color-pink-2: #FF5059;
$color-pink-3: #fef5f0;
$color-green-2: #82D2A0;
$color-green-3: #D2F0DC;
$color-yellow: #FFDC69;
$color-dark-green: #215833;
$color-mid-green: #88c79d;
$color-light-green: #d3e9da;


$color-success: $color-green-2;
$color-error: $color-mid-green;
$color-warning: $color-yellow;

$unit: 0.5rem;

// Fonts
$font-stack-primary: 'Poppins','Avenir','system-ui','Segoe UI','Roboto','Helvetica','Arial',sans-serif;
$font-stack-motley: 'TypeType','SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

$font-stack-system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-stack-monospace: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

// General Breakpoints
$breakpoints: (
  'XS': ( max-width:  40em ),
  'S': ( max-width:  48em ),
  'S-up': ( min-width: 48em ),
  'M': ( max-width:  64em ),
  'ML': ( max-width:  68em ),
  'L': ( max-width: 91em ),
  'XL': ( min-width: 91em ),
  'XXL': ( min-width: 105em ),
  'XXXL': ( min-width: 112em )
);
