.sweetnr-container {



    .upc-slide-callout {
        border-radius: 8px 0 0 8px;

        &.open {
            border-radius: 8px 0 0 0;
        }
    }

    .upc-slide-callout .upc-slideout-title .upc-slideout-title-span {
        text-transform: none;
        font-weight: 900;
        font-size: 1rem;
        border: none !important;
    }

    .upc-slide-callout .upc-slideout-content p {
        font-size: 0.7778em;
        line-height: 1.125rem;
    }

    .upc-slide-callout .upc-slideout-content a {
        font-size: 1rem;
        letter-spacing: 0;
        border-radius: 0 0 0 8px;
    }

    .upc-slide-callout .upc-slideout-title {
        border: none !important;
    }

    .upc-slide-callout .upc-slideout-content {
        border: none !important;
        width: 100%;
        border-radius: 0 0 0 8px;
    }

    .upc-slide-callout .upc-slideout-title .upc-slideout-close {
        width: 6%;
    }

}

