.footer-spacer {
    height: 5em;
}

footer {
    position: relative;
    padding-top: 1em;
    padding-bottom: 7em;
    background: $color-green-3;
    width: 100%;

    .footer-top {
        position: absolute;
        bottom: calc(100% - 2px);
        left: 0;
        width: 100%;
        overflow: hidden;

        svg {
            display: block;
        }
    }
    .footer-blob {
        position: absolute;
        bottom: 0;
        right: 1em;
    }
    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 1;

        @include breakpoint(XS) {
            display: block;
        }
    }
    .copyright {
        margin-bottom: 1rem;
        font-size: 0.7778em;
        max-width: 27rem;

        @include breakpoint(XS) {
            text-align: center;
            max-width: none;
        }

        span {
            display: inline;
            @include breakpoint(S) {
                display: block;
            }
        }

        a {
            color: $color-body;

            &:hover {
                color: $color-dark-green;
            }
        }
    }
    .social-address {
        font-size: 0.7778em;
        display: flex;
        align-items: center;

        @include breakpoint(XS) {
            text-align: center;
            justify-content: center;
            flex-wrap: wrap;

            span {
                width: 100%;
                padding-top: 1em;
            }
        }
        a {
            margin-right: 0.75em;
            width: 2em;
            height: 2em;

            &:first-child {
                margin-left: -0.5em;
            }
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
            &:hover {
                transform: translateY(-0.125em);
            }
            @include breakpoint(XS) {
                margin: 0 0.5em;
            }
        }
    }
    ul {
        padding: 0;
        margin: 0 0 2em 0;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(S) {
            margin: 0 0 1em 0;
        }

        li {
            padding: 0;
            margin: 0 1em 0 0;

            &:last-child {
                margin: 0;
            }
            &:before {
                display: none;
            }
            a {
                color: $color-body;
                font-size: 0.7778em;

                &:hover {
                    color: $color-dark-green;
                }
            }
        }
    }
    .mob-footer-menu-toggle {
        display: none;
        border-bottom: 2px solid $color-body;
        padding: 0.25em 2em;
        margin: 0 auto;
        color: $color-body;
        width: 12em;
        justify-content: center;
        align-items: center;

        svg {
            margin-left: 0.5em;
            @extend %nav-transition;
        }
        @include breakpoint(XS) {
            display: flex;
        }
        &.active {
            svg {
                transform: rotate(-180deg);
            }
        }
    }
    .footer-menu-wrap {
        display: flex;
        width: 100%;

        @include breakpoint(S) {
            flex-direction: column;
        }
        
        @include breakpoint(XS) {
            display: none;
        }
    }
    .green-awards {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        margin-bottom: 0.25em;
        margin-left: auto;
        max-width: 27rem;

        @include breakpoint(L) {
            flex-wrap: wrap;
        }

        @include breakpoint(XS) {
            justify-content: center;
        }

        a {
            margin-left: 1.5em;

            @include breakpoint(XS) {
                margin: 0 0.5em;
                display: flex;
                width: 8em;
                height: auto;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
        @include breakpoint(XS) {
            flex-wrap: nowrap;
        }
    }
    .footer-60 {
        width: 60%;
        padding-top: 1em;

        @include breakpoint(M) {
            width: 50%;
        }
        @include breakpoint(XS) {
            width: 100%;
            padding-top: 0;
        }
    }
    .footer-40 {
        width: 40%;

        @include breakpoint(M) {
            width: 50%;
        }
        @include breakpoint(S) {
            width: 60%;
            padding-right: 2em;
        }
        @include breakpoint(XS) {
            width: 100%;
            overflow: hidden;
            padding-right: 0;
        }
    }
    &.group-site {
        z-index: 2; // So the skewed footer always sits on top of the footer.

        .footer-blob {
            bottom: 0;
        }

        .footer-40 {
            text-align: right;

            @include breakpoint(XS) {
                text-align: center;
            }
        }
        @include breakpoint(XS) {
            ul li {
                margin: 0;
                width: 100%;
                text-align: center;
            }
            .mob-footer-menu-toggle {
                margin-bottom: 1em;
            }
            .social-address {
                padding-top: 1em;
            }
        }
    }
    &.location-site {
        overflow: hidden;
        .footer-60 {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            padding-top: 4em;

            @include breakpoint(XS) {
                display: block;
                padding-top: 0;
            }
        }
        @include breakpoint(XS) {
            ul {
                margin-bottom: 1em;

                li {
                    width: 100%;
                }
            }
        }
        // .footer-40 {
        //     overflow: hidden;
        // }
        .footer-menu {
            display: flex;
            max-width: 34em;
            width: 100%;
            padding-left: 1.5em;

            @include breakpoint(S) {    
                padding-left: 0;
            }

            @include breakpoint(XS) {
                text-align: center;
                flex-direction: column;
                max-width: none;
            }
            .menu {
                flex-grow: 1;
                flex-basis: 0;
                
                &:first-child {
                    padding-right: 1.5em;
                }

                @include breakpoint(M) {
                    padding-right: 0;
                }

                @include breakpoint(S) {
                    flex-basis: auto;
                }

                > li > a {
                    font-size: 1em;
                    margin-bottom: 1em;
                    display: block;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 50%;
                        margin: 0;

                        @include breakpoint(XS) {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            a {
                                line-height: 1;
                                padding: 0.5em 0;
                            }
                        }
                    }
                }
            }
        }
        .mob-footer-menu-toggle {
            margin-bottom: 2em;
        }
        .green-awards {
            margin-bottom: 0.75em;
        }
        .payments {
            text-align: right;
            display: flex;
            align-items: center;

            svg {
                margin-left: 0.25em;
            }
            @include breakpoint(XS) {
                text-align: center;
                margin: 0 auto;
                display: block;
            }
        }

        .tripadvisor-widget {
            margin-top: 1rem;
            @include breakpoint(XS) {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.footer-newsletter {
    padding-top: 4em;
    padding-bottom: 4em;
    margin-bottom: 4em;
    position: relative;

    @include breakpoint(XS) {
        padding-left: 2em;
        padding-right: 2em;
        margin-bottom: 7em;
    }
    p {
        width: 100%;
        margin-bottom: 0.5rem;
        font-size: 0.7778em;
        text-align: center;
        position: relative;
        z-index: 5;

        &.small {
            font-size: 0.55em;
            display: block;
            max-width: 30em;    
            margin: 0 auto;
        }
    }
    input[type="text"],
    input[type="email"] {
        border: 0;
    }
    form {
        position: relative;
        z-index: 5;
        border-bottom: 2px solid $color-body;
        padding-left: 2.25em;
        padding-right: 2.5rem;
        margin: 0 auto;
        max-width: 18em;

        @include breakpoint(XS) {
            font-size: 0.75em;
            padding-left: 2.75em;

            .input-wrap {
                label {
                    top: 1rem;
                }
                &.label-active {
                    label {
                        top: 0;
                    }
                }
            }
        }
        > svg {
            position: absolute;
            left: 0;
            bottom: 0.25em;
        }
        input {
            margin: 0;
            min-height: 0;
            padding-bottom: 0.25em;

            &:focus {
                border: 0;
            }
        }
        .button {
            position: absolute;
            right: 0;
            bottom: 0.25em;
            z-index: 3;
            padding: 0;
            width: 2.5em;
            height: 2.5em;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                svg path {
                    stroke: $color-dark-green;
                }
            }
        }
        .input-wrap.label-active input {
            border: 0;
        }
        .checkbox {
            position: absolute;
            left: 0;
            bottom: -3em;
            z-index: 100;
            font-size: 0.75em;

            label {
                padding-right: 0;
                padding-left : 0;
                margin-left: 0;
            
                &:before {
                    left: auto;
                    right: -2.5em;
                    width: 1.125em;
                    height: 1.125em;
                    top: -0.2em;
                }
                &:after {
                   display: none;
                }
            }
            input {
                appearance: checkbox;
                opacity: 0;
                position: absolute;
                top: -0.2em;
                right: 0.5em;

                &:checked {
                    + label {
                        &:before {
                            border: 2px solid $color-body;
                            background: $color-dark-green; 
                        }
                    }
                }
            }
        }
        #optIn {
            position: absolute;
            right: 0.3em;
            top: 0.3em;
            display: none;
        }
    }
    .form-bg {
        position: absolute;
        transform: rotate(-41deg);
        z-index: 2;
        left: 3em;
        top: -5.5em;
        width: 25em;
        height: auto;
        min-height: 25em;

        @include breakpoint(M) {
            left: 0;
        }
        @include breakpoint(S) {
            left: -1em;
        }
        @include breakpoint(XS) {
            top: -3em;
            left: 1em;
            width: 20em;
            min-height: 0;
        }
    }
    .form-tail {
        position: absolute;
        transform: rotate(-102deg);
        right: 7em;
        bottom: -7em;
        z-index: 3;
        width: 13em;
        @include breakpoint(M) {
            right: 5em;
        }
        @include breakpoint(S) {
            right: 0;
        }
        @include breakpoint(XS) {
            right: 0em;
            top: 8em;
        }

    }
    .form-rear {
        position: absolute;
        transform: rotateY(-180deg);
        left: 0;
        top: 0;
        width: 15em;
    }
    // &.amsterdam {
    //     .submitButton {
    //         position: static;
    //         padding: 0.675em 1.75em;
    //         margin-top: 1rem;

    //         @include breakpoint(S) {
    //             margin-top: 0;
    //         }
    //     }
    //     .mpFormLabel {
    //         font-weight: 700;
    //         font-size: 0.75em;
    //     }
    //     form {
    //         padding: 0;
    //         border: 0;

    //         li {
    //             margin: 0;
    //             width: 50%;
    //             padding: 0 0.5em;

    //             @include breakpoint(S) {
    //                 width: 100%;
    //                 margin-bottom: 1em;
    //             }
    //         }
    //     }
    //     label.error {
    //         padding-top: 0.5em;
    //         font-size: 0.675em;
    //         color: $color-error;
    //         display: block;
    //     }
    //     input[type="text"] {
    //         border-bottom: 2px solid $color-body;
    //         padding-top: 0;
    //     }
    //     .form-bg {
    //         width: 27em;

    //         @include breakpoint(XS) {
    //             width: 20em;
    //         }
    //     }
    //     .form-tail {
    //         right: 3em;
    //         bottom: -2em;
    //     }
    //     .mpErrorSummary {
    //         display: none !important;
    //     }
    // }
}
#ouibounce-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  
    .custom-modal-close {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 12;
        cursor: pointer;
        padding: 0.5em;

        svg {
            width: 2em;
            height: 2em;
            display: block;
        }
    }
    .underlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color-body, 0.9);
        cursor: pointer;
        animation: fadein 0.5s;
    }
    .modal {
        z-index: 1;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        animation: fadein 0.3s;
        background: $color-body-cream;
        display: flex;
        text-align: center;
        
        @include breakpoint(XS) {
            width: 90vh;
            height: 90vw;
            display: block;
        }
    }
    .modal-title {
        margin-bottom: 0.5rem;
    }
    li {
        text-align: center;
        padding-left: 0;
        padding-bottom: 0.75em;
        border-bottom: 1px solid $color-pink-3;

        &:before {
            display: none;
        }
    }
    .modal-inner {
        padding: 2em;

        p:last-child {
            margin: 0;
        }
    }
    .modal-footer {
        margin-bottom: 1.5rem;

        p {
            cursor: pointer;
            display: inline;
            color: $color-body;
            border-bottom: 1px solid $color-body;
            font-size: 0.85em;

            &:hover {
                color: $color-dark-green;
            }
        }
    }
  }
  @keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  @keyframes popin {
    0% {
        transform: scale(0);
        opacity: 0;
    }
  
    85% {
        transform: scale(1.05);
        opacity: 1;
    }
  
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.motley-footer {
    .container-inner {
        border-top: 2px solid $color-body;

        .content-element {
            margin-bottom: 0;
        }
    }
}

.no-cssgrid {
    footer {
        .footer-menu-wrap {
            display: block;
        }
    }
}

.fancybox-button.fancybox-button--fullscreen {
    display: none !important;
}

.site-3 .page-id-5 .paypal {
    display: none;
}

.grecaptcha-badge {
    display: none;
}

#onetrust-banner-sdk {
    background-color: $color-light-green !important;
    #onetrust-policy-text {
        margin-bottom: 0.625em;
        color: $color-body;
    }
    #onetrust-pc-btn-handler,
    #onetrust-pc-btn-handler.cookie-setting-link {
        outline: 0 !important;
        color: $color-body;
    }
    #onetrust-accept-btn-handler {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: $color-body !important;
        border-color: $color-body !important;
        color: #fff;
        border-radius: 2rem;
    }
}

#onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    background-color: $color-dark-green;
    border-radius: 2rem;
    color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    text-decoration: none;
}

#onetrust-consent-sdk {
    #accept-recommended-btn-handler,
    .save-preference-btn-handler.onetrust-close-btn-handler.button-theme {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: $color-body;
        border-color: $color-body;
        color: #fff;
        border-radius: 2rem;
        outline: 0 !important;
    }
}

//Styling for Qbic Manchester's Restaurant Booking Bar

.manchester-restaurant-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(XS) {
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        padding: 0 1.5rem;
    }

    h5 {
        flex-grow: 1;
        margin-bottom: 0;

        @include breakpoint(XS) {
            text-align: center;
        }
    }

    .booking-mask {
        flex-shrink: 0;
        width: auto;
        align-items: center;
        display: flex;
        gap: 1.5rem;

        @include breakpoint(XS) {
            flex-direction: column;
        }
    }

}