.faqs {
    .accordion {
        .accordion-title {
            border-bottom: 2px solid rgba(33,87,50,0.25);
        }
        .accordion-group {
            border-bottom: none;
        }  
    }
    .faqs-sidebar {
        float: left;
        width: 25em;
        padding-right: 7em;
        margin-top: 7em;
        z-index: 2;
        top: 7em;

        @include breakpoint(M) {
            width: 15em;
            padding-right: 1em;
        }
        @include breakpoint(XS) {
            display: none;
        }
    }
    .faqs-nav-wrapper {
        margin-bottom: 3em;
    }
    .faq-nav {
        margin: 0;
        padding: 1.5em 0;
        z-index: 2;
        position: relative;

        li {
            padding: 0;
            margin: 0;
            border-bottom: 2px solid rgba($color-body, 0.25);

            &:before {
                display: none;
            }
            &:last-child {
                margin: 0;
            }
        }
        a {
            display: block;
            font-weight: 700;
            color: $color-body;
            opacity: 0.25;
            padding: 0.5em 0;

            span {
                margin-right: 1em;
            }
            &:hover {
                opacity: 1;
            }
            &.active {
                color: $color-body;
                opacity: 1;
            }
        }
    }
    .faqs-content {
        float: right;
        margin-top: 7em;
        width: calc(100vw - 35em);
        padding-left: 1em;
        position: relative;
        z-index: 3;

        @include breakpoint(M) {
            width: calc(100vw - 20em);
        }
        @include breakpoint(XS) {
            width: 100%;
            margin-top: 7em;
            padding-left: 0;
        }
    }
}

.faqs-search {
    padding: 0.5em 0 0 0;
    position: relative;
    border-bottom: 2px solid $color-body;

    svg {
        position: absolute;
        left: 0;
        top: 1.5em;
    }
    .input-wrap {
        margin-left: 2.5em;

        input {
            margin: 0;
            border: 0;
        }
    }
}

.faqs-notification {
    margin-bottom: 2em;
    display: none;

    @include breakpoint(XS) {
        text-align: center;
    }
    &.active {
        display: flex;
    }
    .results-for {
        padding: 1em;
        background: rgba($color-pink, 0.15);
        color: $color-dark-green;

        @include breakpoint(XS) {
            flex-grow: 1;
        }
    }
    .filter-count {
        padding: 1em;
        background: $color-dark-green;
        color: $color-body-cream;
        font-weight: 700;
    }
}

.no-results {
    display: none;
}

.faqs-top {
    padding-bottom: 2em;
}

.faq-icon-container {
    position: relative;

    .faq-blob {
        position: absolute;
        bottom: -4.5rem;
        right: 0;
        min-width: calc(100% + 11.5rem);
        z-index: -1;
    }
}