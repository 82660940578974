//Custom styling for the Brussels booking engine

.ibe-wrapper {
    overflow: hidden;
}

.page-template-page-embedded-ibe {
    .sweetnr-container,
    .booking-bar,
    .mob-controls {
        display: none !important;
    }
    .ibe-form-control {
        font-family: $font-stack-primary !important;
    }
    .ibe-btn {
        font-weight: 700;
        text-transform: unset !important;

        &:hover {
            transform: none;
            color: $color-body;
        }
    }
    .up-ibe {
        .ibe-guest-booker-details {
            border-bottom: 2px solid $color-body;

            dt {
                font-weight: 700 !important;
                color: $color-body !important;
            }
        }
        .ibe-reservation-booker {
            h4 {
                font-weight: 700 !important;
            }
        }
        .ibe-search-box {
            box-shadow: none;
            border-radius: 4px;
            background: none;
            padding: 0;
            border: 2px solid $color-body;
        }
        .ibe-search-box-form {
            .ibe-btn {
                margin-top: 0;
                margin-bottom: 0;
                min-width: 160px;
                font-weight: bold;
            }
            .ibe-form-group {
                border-right: 1px solid $color-body;
                margin: 0;
                max-width: none !important;

                @include breakpoint(S) {
                    border-right: 0;
                    border-bottom: 1px solid $color-body;
                }
                &.ibe-promo-code {
                    border-right: 0;
                }
                label {
                    font-weight: bold !important;
                    position: absolute;
                    top: 0.5rem;
                    z-index: 3;
                    text-transform: unset !important;
                    margin: 0;
                    font-size: 0.675rem;
                }
            }
            .ibe-persons-qty-selector .ibe-persons-qty-selector-button {
                width: 1.5rem;
                height: 1.5rem;
                color: $color-dark-green;
                padding: 0;
            }
            .ibe-search-box .ibe-property-name,
            .ibe-persons-qty .ibe-persons-qty-field {
                height: 3.5rem !important;
                background: none !important;
                color: $color-body;
            }
            .ibe-form-control {
                height: 3.5rem;
                margin: 0;
                border: 0;
                background: none;
                padding-bottom: 0;
                color: $color-body !important;

                &.ibe-date-input {
                    height: 3.25rem;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding-bottom: 0.65rem;
                    &.ibe-date-input-active {
                        border: none;
                    }
                }
            }
        }
        .ibe-steps-bar {
            border-bottom: 2px solid $color-body;

            @include breakpoint(XS) {
                border-bottom: 0;
            }
        }
        .ibe-steps-bar .ibe-steps .ibe-step {
            background: #fff !important;
            border: 4px solid $color-pink !important;
            box-sizing: content-box;

            &.ibe-active-step {
                color: $color-body-cream !important;
                background: $color-pink !important;
            }
        }
        .ibe-room-result {
            border-radius: 4px;
            padding: 0 !important;
            margin-bottom: 3rem !important;
            border: 2px solid $color-body !important;
            position: relative;
            background: $color-body-cream;

            &:nth-child(1) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -25%;
                    width: 34em;
                    height: 34em;
                    background: url(/wp-content/themes/qbic/images/ibe-room-blob-1.svg);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }
            &:nth-child(2) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: -25%;
                    width: 24em;
                    height: 24em;
                    background: url(/wp-content/themes/qbic/images/ibe-room-blob-2.svg);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }
            &:nth-child(3) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -25%;
                    width: 30em;
                    height: 30em;
                    background: url(/wp-content/themes/qbic/images/ibe-room-blob-3.svg);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }
            .ibe-room-description {
                padding-right: 1rem;
            }
            .ibe-col-md-8 {
                padding: 1.5rem;

                .ibe-col-md-9 {
                    position: relative;
                    z-index: 3;
                }
            }
            .ibe-col-md-3 {
                text-align: center;
                position: relative;
                z-index: 2;
                transform: translateY(-2rem);

                &:after {
                    content: "";
                    position: absolute;
                    top: -4rem;
                    right: -0.75rem;
                    width: 14em;
                    height: 14em;
                    background: url(/wp-content/themes/qbic/images/ibe-price-blob.svg);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    z-index: -1;

                    @include breakpoint(M) {
                        width: 12em;
                        height: 12em;
                    }
                    @include breakpoint(S) {
                        right: -2.5rem;
                    }
                    @include breakpoint(XS) {
                        right: 50%;
                        transform: translateX(50%);
                    }
                }
            }
        }
        .ibe-room-results-list {
            margin-top: 2rem;

            .ibe-room-from-text {
                font-size: 0.875rem;
            }
            .ibe-from-price {
                font-size: 2rem;
            }
            .ibe-per-night-text {
                display: block;
                font-size: .75rem;
            }
        }
        .ibe-room-results-header {
            border: 0;

            .ibe-room-results-title {
                text-transform: unset !important;
                color: $color-body !important;
            }

            .ibe-availability-calendar-button {
                background-color: $color-pink !important;
                color: #fff !important;

                &:hover {
                    background-color: #f5f5f5 !important;
                    color: $color-pink !important;
                }
            }
        }
        .ibe-cart {
            margin-bottom: 1rem;
        }
        .ibe-rate-options {
            position: relative;
            z-index: 3;

            .ibe-unit-qty-button {
                border-radius: 0;
            }
            .ibe-btn {
                font-size: 0;

                &:after {
                    content: "Book";
                    font-size: 1rem;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .ibe-checkout-btn {
            margin-top: 1rem !important;
            border-radius: 4rem;
            text-transform: unset !important;
            position: static;
            background: $color-dark-green;
            color: $color-body-cream !important;

            &:hover {
                color: $color-body !important;
            }
        }
        .ibe-cart-top {
            border-bottom: 2px solid $color-body;

            &:hover {
                background: $color-pink-3 !important;
            }
            .ibe-room-count {
                text-transform: unset !important;

                .ibe-room-count-label {
                    color: $color-body !important;
                }
            }
        }
        .ibe-btn {
            padding: 0.675em 1.75em;
        }
        .ibe-add-another-room-btn {
            text-transform: unset;
            border-radius: 8px;
            background: $color-pink !important;
            color: #fff !important;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                margin-right: 1.5rem;
            }
        }
        .ibe-total-cost {
            border-radius: 4px;
            text-transform: unset;
            border: 2px solid $color-body;
            padding: 0 0 0 1rem;
            margin-top: 0;
            height: auto!important;

            @media screen and (max-width: 1200px) {
                padding-left: 0;
            }
            .ibe-total-cost-label,
            .ibe-total-cost-value {
                font-size: 1.5rem;
                font-weight: 700;

                @media screen and (max-width: 1200px) {
                    padding: 0.75rem;
                }
            }
            &.open {
                border-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        .ibe-vat-breakdown-btn {
            background: $color-pink !important;
            color: #fff !important;
            padding: 1.5rem 1rem;
            font-size: 1rem !important;
            text-transform: none !important;
            @extend %transition;

            &:hover {
                background: $color-body !important;
            }
            @media screen and (max-width: 1200px) {
                padding: 0.75rem;
                margin-top: 0 !important;
            }
        }
        .ibe-add-another-room-btn {
            margin-top: 4rem;
            position: relative;
            overflow: visible;

            &:hover {
                background: $color-body !important;
                transform: none;
            }
            &:before {
                content: "";
                position: absolute;
                top: -2rem;
                left: 0;
                width: 100%;
                height: 2px;
                background: $color-body;
            }
        }
        .ibe-total-tax-breakdown {
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &.open {
                border: 2px solid $color-body;
                padding: 1rem;
            }
        }
        .ibe-total-row-label {
            color: $color-body;
        }
        .ibe-total-row-value {
            color: $color-dark-green;
        }
        .ibe-reservation {
            border: 2px solid $color-body;
            border-radius: 4px;
            background: $color-body-cream;

            &.has-extras {
                border-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .ibe-reservation-extras {
            border: solid $color-body !important;
            border-width: 1px 0 !important;
        }
        .ibe-add-extras-btn {
            margin-top: 0.5rem;
            min-width: 0;
            font-size: 0.75rem;
        }
        .ibe-reservation .ibe-detail {
            .ibe-property-title {
                font-weight: 700;
                text-transform: unset !important;
            }
            .ibe-stay-details {
                text-transform: unset !important;
            }
            .ibe-title {
                color: $color-body !important;
                font-size: 1rem !important;
            }
        }
        .ibe-checkout-step-title,
        .ibe-checkout-step-title {
            text-transform: unset !important;
            color: $color-body !important;
        }
        // .ibe-custom-select,
        // input.ibe-form-control,
        // textarea.ibe-form-control {
        //     padding-left: 0;
        // }
        .ibe-checkout-step-box {
            border-radius: 4px;
            background: $color-body-cream;
            border: 2px solid $color-body;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -25%;
                width: 30em;
                height: 30em;
                background: url(/wp-content/themes/qbic/images/ibe-checkout-blob.svg);
                background-size: 100%;
                background-repeat: no-repeat;
                z-index: -1;
            }
            label {
                font-weight: 700;
            }
            input[type=checkbox] {
                margin-right: 0.5rem;
            }
            .ibe-form-control {
                border: 1px solid $color-body;
            }
            .ibe-custom-select {
                border: 1px solid $color-body;
                color: $color-body !important;
            }
            .ibe-btn {
                border-radius: 4rem;
            }
        }
        @include breakpoint(XS) {

            //Overriding input type=date styles for mobile devices
            // NB: Currently Firefox mobile does not seem to honour text-align values for input type=date
            .ibe-arrival-date-input,
            .ibe-departure-date-input {
                 padding-left: 2rem;
                 text-align: center;
                 font-family: inherit;
                 height: auto !important;
                 padding-top: 1.5rem;
                 padding-bottom: 0.5rem !important;
            }
        }
    }

    .ibe-cart-reservations .ibe-reservation {
        border: 0 !important;
    }

    .ibe-cart.open .ibe-cart-reservations {
        border-bottom: 2px solid $color-body !important;
    }

    .ibe-extra-result {
        border: 0 !important;
        margin-bottom: 0 !important;
        padding: 1.5rem 1rem !important;
        border-bottom: 2px solid $color-body !important;

        .ibe-extra-details {
            align-items: flex-start;
        }
        &:hover {
            background: $color-pink-3 !important;
        }
        &.ibe-extra-selected {
            background: $color-pink !important;
            .ibe-extra-name,
            .ibe-extra-details {
                color: #fff4f0 !important;
                .ibe-extra-action {
                    .ibe-extra-price{
                        color: #fff4f0 !important;
                    }
                    .ibe-extra-toggle-status {
                        color: $color-pink !important;
                        background-color: #fff4f0 !important;
                        -webkit-text-stroke: 1px #ff8abd;
                    }
                }
            }
        }
        .ibe-extra-name {
            font-weight: 700;
            text-transform: unset !important;
            margin: 0 0 0.5rem 0 !important;
        }
        .ibe-extra-price {
            font-weight: 700;

            .ibe-extra-pricing-unit {
                color: #9b9b9b !important;
                font-size: 0.75rem !important;
            }
        }
        .ibe-extra-content .ibe-extra-description {
            font-size: 0.75em !important;
            line-height: 1.3;
        }
    }

    .ibe-steps-bar .ibe-step-title {
        text-transform: unset !important;
    }

    .mat-datepicker-content {
        background: $color-body-cream;
        border-radius: 0.5rem;
    }

    .ibe-skip-extras-btn {
        text-transform: unset !important;
        font-weight: 700;
        color: $color-body !important;
        display: none !important;
    }

    .mat-dialog-container {
        background: $color-body-cream;
        color: $color-body;
        border-radius: 0.5rem;

        .ibe-carousel-next, .ibe-carousel-prev {
            padding: 0;
        }
        .carousel-image {
            height: 20em;
        }
        button {
            border-radius: 4em;
        }
    }

    .mat-dialog-title {
        font: inherit;
        text-transform: unset !important;
        font-weight: 700;
    }

    .ibe-extra-toggle-status {
        border-radius: 100% !important;
        height: 35px !important;
        width: 35px !important;
        -webkit-text-stroke: 1px;
    }

    .ibe-complete-extras-btn {
        text-transform: unset !important;
        border-radius: 4em;
    }

    .ibe-reservation {
        border: 2px solid $color-body !important;
    }

    .mat-calendar {
        font-family: $font-stack-primary;

        table th,
        table tr td {
            border: 0;
            background: none;
        }
        table th {
            color: $color-dark-green;
        }
        table tr:nth-child(even) {
            background: none;
        }
    }

    .ibe-checkout-step-checklist {
        li {
            border: 2px solid $color-body !important;
            border-radius: 4px;
            padding-left: 0;
            background: $color-body-cream;

            &:before {
                display: none;
            }
        }
    }

    .ibe-room-title {
        color: $color-body !important;
    }

    .ibe-persons-qty-popup {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    ibe-persons-qty-popup {
        background: $color-body-cream !important;
    }

    .ibe-custom-select {
        background-color: transparent;
        border: 0;
        font-family: $font-stack-primary;
        color: $color-body !important;
    }

    .ibe-property-id {
        select {
            margin-top: 1rem;
        }
    }

    ibe-modify-reservation {
        p {
            color: $color-pink !important;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 5px;
        }
    }

    .ibe-reservation-unit-title {
        p {
            color: $color-pink !important;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 5px;
        }
    }

    .ibe-guest-management-edit-details-header,
    .ibe-guest-management-bookings-header {
        h1 {
            font-weight: 700 !important;
            font-size: 1.5rem !important;
        }
    }

    .ibe-reservatiopn-guest-management-detail .ibe-reservation-detail-header .ibe-reservation-detail-action-buttons a {
        padding: 0.675em 1.75em !important;
        border-radius: 4em !important;

        &.ibe-cancel-rerservation-btn {
            border: 2px solid $color-pink !important;
        }
    }
}

.page-template-page-embedded-ibe {
    .up-ibe {
        .ibe-property-id {
            display: none;
        }
    }
}

.ibe-form-group.ibe-marketing-optin {
    .ibe-terms-confirm-text {
        display: none;
    }
    &:after {
        content: "Yes please! I want to receive cheap stay offers from Qbic";
        margin-left: 2em;
        vertical-align: middle;
    }
}

// .site-4 { asdasdasd
//     padding: 2em;
//     .ibe-booking-login-form-box form, input {
//         background-color: transparent;
//         border: 2px $color-body solid!important;
//     }
//     .ibe-btn {
//         border-radius: 8px;
//     }
// }

@media print {
    .ibe-add-another-room-btn,
    .menu-wrapper {
        display: none !important;
    }
    .ibe-total-tax-breakdown {
        border: 2px solid $color-body;
        padding: 1rem;
        display: block !important;
        max-height: none !important;
    }
    .booking-confirmation {
        .ibe-top-header,
        .ibe-vat-breakdown-btn,
        .ibe-print-btn,
        .ibe-map .blob,
        .ibe-map .button {
            display: none !important;
        }
        .img-content.text-image .img {
            margin: 2rem 0;
        }
        .ibe-total-cost {
            padding: 1rem;
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .ibe-wrapper {
            padding-bottom: 0;
        }
        .up-ibe {
            margin-bottom: 2rem;
        }
        .boxes {
            page-break-after: always;

            .box {
                width: calc(50% - 2.5rem);
                background: none;
                border: 2px solid $color-body;
            }
        }
    }
}
.ibe-print-btn {
    border-radius: 4rem;
}
.ibe-room-info-icon {
    color: $color-body;
}
.page-template-page-embedded-ibe .up-ibe .ibe-room-result .ibe-col-md-8 .ibe-col-md-9 {
    margin-bottom: 0;
}
@media only screen and (max-width: 990px) {
    .ibe-step-title {
        display: none;
    }
}
@include breakpoint(XS) {
    .ibe-extra-image-inner {
        max-width: 100px;
        margin: 0 auto;
    }
    .ibe-extra-action {
        padding-left: 0;
        justify-content: center;
    }
}
#cardfront {
    box-shadow: none!important;
    border: 1px solid black;
}
.ibe-guest-manage-page-link {
    @include breakpoint(XS) {
        top: 1rem !important;
    }
}
.ibe-login-link {
    &:after {
        content: " to MyQbic Account";
        font-weight: 800;
        // width: 4rem;
        // height: 2rem;
        // position: absolute;
        // right: 0;
        // top: 0;
        // background-image: url(/wp-content/themes/qbic/images/my_qbic_login.svg);
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        // transform: translateY(-10px);
    }
}
.ibe-guest-management-header .ibe-guest-management-heading.ibe-user-heading a svg {
    height: 1.5em;
}
.page-template-page-embedded-ibe {
    #header-book {
        display: none !important;
    }
    .myqbic-button-header {
        padding: 0.675em 1.75em;
    }
    .up-ibe {
        .ibe-row {
            .ibe-col-md-3 {
                &:after {
                    top: -4rem;
                    background: url(/wp-content/themes/qbic/images/ibe-price-blob-pink.svg);
                    @media only screen and (max-width: 967px) {
                        right: 50%!important;
                        transform: translate(46%, 0px);
                    }
                }
            }
        }
        .ibe-radio-inner {
            display: none;
        }
        .ibe-room-from-text {
            text-transform: capitalize;
        }
        .ibe-from-price {
            color: $color-body!important;
            .ibe-per-night-text {
                color: $color-body!important;
                text-transform: capitalize;
            }
        }
        .ibe-room-image {
            height: calc(100% - 49px);
            @include breakpoint(S) {
                height: 18em;
            }
        }
        .ibe-rate-option {
            min-height: 60px!important;
            border-bottom: $color-body 1px solid!important;

            .ibe-rate-title {
                color: $color-body!important;
            }
            &:first-child {
                .ibe-rate-option-left {
                    border-top-left-radius: 5px;
                }
                .ibe-btn {
                    border-top-right-radius: 5px;

                    @media screen and (max-width: 1200px) {
                        border-top-right-radius: 0;
                    }
                }
            }
            &:last-child {
                border-bottom: 0 !important;

                .ibe-rate-option-left {
                    border-bottom-left-radius: 5px;
                }
                .ibe-btn {
                    border-bottom-right-radius: 5px;
                }
            }
            &.selected {
                @include breakpoint(XS) {
                    border-bottom: none!important;
                }
                .ibe-rate-option-left {
                    background-color: $color-pink-3!important;
                }
                .ibe-radio-inner {
                    border: solid 1px $color-pink-3!important;
                    display: block;
                }
            }
        }
        .ibe-unit-qty-display {
            position: relative;
            padding-top: 10px!important;

            &:after {
                font-family: $font-stack-primary;
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 13px;

                content: "Rooms";
                position: absolute;
                top: 8px;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
        .ibe-option-right-wrapper {
            .ibe-unit-qty-button, .ibe-unit-qty-display {
                width: 2em!important;
                background-color: $color-body!important;
                color: $color-pink!important;
                &::before {
                    display: none;
                }
                @include breakpoint(M) {
                    width: 2em!important;
                }
            }
            .ibe-unit-qty-button {
                &:hover {
                    transform: none;
                    &::before {
                        opacity: 0.7;
                    }
                }
            }
            .ibe-icon-chevron-right, .ibe-icon-chevron-left {
                overflow: visible;
                position: relative;

                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
                &:after {
                    content: "";
                    position: absolute;
                    display: flex;
                    width: 1rem;
                    height: 1rem;
                    top: 60%;
                    justify-content: center;
                    align-items: center;
                    transform: translate(0,-50%);
                    background-color: $color-pink!important;
                    border-radius: 50%;
                    background-size: 70%!important;
                    background-repeat: no-repeat!important;
                    background-position: center!important;
                }
            }
            .ibe-icon-chevron-right {
                &:after {
                    background: url(/wp-content/themes/qbic/autoload-svgs/plus-white.svg);
                    left: 0;
                }
            }
            .ibe-icon-chevron-left {
                &:after {
                    background: url(/wp-content/themes/qbic/autoload-svgs/minus-white.svg);
                    right: 0;
                }
            }
            .ibe-btn {
                background-color: $color-pink!important;

                @media screen and (min-width: 1200px) {
                    width: 120px !important;
                }
                @include breakpoint(XS) {
                    border-top-right-radius: 0px!important;
                }
            }
        }
    }
    .ibe-checkout-step-box {
        padding: 2rem;

        input, select, textarea {
            background: none!important;
            border: none!important;
            border-bottom: 2px $color-body solid!important;
            color: $color-pink !important;
            padding-left: 0;
            padding-right: 0;

            @include placeholder {
                color: $color-pink !important;
            }
        }
        .ibe-custom-select {
            color: $color-pink !important;
            position: relative;
            background: url(/wp-content/themes/qbic/autoload-svgs/arrow-down-green.svg) no-repeat right transparent!important;
        }
        label {
            color: $color-body !important;
            font-family: $font-stack-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
.ibe-reservation {
    .ibe-rate-plan-title, .ibe-property-title, .ibe-stay-details {
        font-size: 1em!important;
    }
    .ibe-rate-plan-title, .ibe-property-title, .ibe-dates {
        color: $color-body!important;
    }
    .ibe-property-title {
        padding-bottom: 1em;
    }
    .ibe-stay-details .ibe-icon {
        &::before {transform: scale(1.2) translateX(-5px);}
    }
    .ibe-image {
        height: auto !important;
    }
    .ibe-price .ibe-remove-btn {
        color: #fff !important;
        background: $color-pink !important;
        border: 1px solid $color-pink !important;

        &:hover {
            background: $color-body !important;
            border: 1px solid $color-body !important;
        }
    }
}
.ibe-adults,
.ibe-icon-calendar + span {
    font-weight: 700;
}

.ibe-reservation-dates-edit-btn {
    display: none !important; // DELETE THIS AFTER CEO DEMO
}

.ibe-reservation-add-extra-btn {
    display: none !important; // DELETE THIS AFTER CEO DEMO
}

.ibe-room-image {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 1rem !important;
        right: 1rem !important;
        top: initial !important;
        width: 2rem;
        height: 2rem;
        background: url(/wp-content/themes/qbic/images/room-mag.svg) !important;
        background-size: 100% !important;
        background-repeat: no-repeat !important;
    }
}
.ibe-form-group .ibe-checkbox-frame .ibe-checkmark {
    border: 2px solid $color-pink !important;

    &:after {
        top: 1px !important;
    }
}
.ibe-checkout-step-checklist-title {
    text-transform: none;
    color: $color-body !important;
}
.ibe-checkout-step-checklist {
    li {
        &:hover {
            background: $color-yellow !important;
        }
    }
}
@include breakpoint(XS) {
    ibe-guest-login {
        padding: 0 !important;
    }
}
.ibe-search-box {
    margin-top: 3rem;
    position: relative;

    .ibe-custom-select {
        @media only screen and (max-width: 991px) {
            width: auto !important;
        }
    }
    &:before {
        content: "*Check-In after 15:00 - Check-Out before 11:00";
        position: absolute;
        top: -1.5rem;
        left: 0;
        color: $color-body;
        font-size: 0.75rem;
        font-weight: 700;
        font-family: $font-stack-primary;
    }
}
.ibe-container {
    max-width: none !important;
    padding: 0;

    .ibe-guest-management-bookings .ibe-guest-management-bookings-table table {
        border: 2px solid $color-body !important;
        border-radius: 8px !important;

        th {
            color: $color-body !important;
        }
        tbody tr td {
            padding: 0.5rem !important;
            color: $color-body !important;
        }
    }
}
.mat-dialog-container {
    border: 2px solid $color-body !important;
}
.ibe-login-title {
    font-size: 1.5rem !important;
    line-height: 1.3;
    margin-bottom: 1rem !important;
    font-weight: 700 !important;
    font-family: $font-stack-primary;
    text-transform: none !important;
    color: $color-body !important;
}
.ibe-guest-login {
    label {
        font-weight: 700;
        font-size: 0.75rem !important;
        text-transform: none !important;
        color: $color-body !important;
    }
    button {
        border-radius: 4em;
    }
}
.ibe-guest-signup {
    button {
        border-radius: 4em;
    }
}
.ibe-create-guest-account {
    max-width: 48rem;
    margin: 2rem auto 0 auto;

    .ibe-guest-create-email-form {
        border-radius: 4px;
        border: 2px solid $color-body;
        padding: 2rem;
    }
    .ibe-form-group label {
        font-size: 0.75rem !important;
        text-transform: none !important;
        color: $color-body !important;
        font-weight: 700;
    }
    h1 {
        font-size: 3.1667em;
        font-weight: 700 !important;
        line-height: 1.3;
        text-transform: none !important;
        margin-bottom: 1.5rem;
        color: $color-body !important;
    }
    p {
        color: $color-body !important;
    }
    ul {
        li {
            padding: 0;

            span {
                color: $color-body !important;
            }
            &:before {
                display: none;
            }
        }
    }
}
.ibe-room-custom-content-closed-description {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.ibe-room-content-button-container {
    .ibe-room-content-button {
        font-weight: 700 !important;
        font-family: $font-stack-primary;
        background: $color-green-3 !important;
        color: $color-body !important;

        span:hover,
        div:hover {
            text-decoration: none !important;
        }
        &:hover {
            background: $color-pink !important;
            color: #fff !important;
            text-decoration: none !important;

            span,
            div {
                color: $color-body;
            }
        }
    }
}
.ibe-extra-result {
    border-bottom: 1px solid $color-body !important;
    padding: 1.5rem 0 !important;

    &:hover {
        background: none !important;
    }
    .ibe-extra-name {
        color: $color-body !important;
        font-size: 1.125rem !important;
    }
    .ibe-extra-price {
        font-size: 1.125rem !important;
    }
    .ibe-extra-action {
        align-items: center;
    }
    .ibe-extra-image {
        height: auto;
    }
}
.ibe-extra-toggle-status {
    margin-left: 1.5rem;
    height: 24px !important;
    width: 24px !important;

    .ibe-icon {
        font-size: 18px !important;
    }
}

.ibe-reservation-footer {
    a {
        color: $color-body !important;
        font-weight: initial;
        @media only screen and (max-width: 770px) {
            padding: 1.25em 0 !important;
        }
    }

    .ibe-reservation-footer-btn {
        padding: 10px 1em !important;
        border-radius: 16px;
        background-color: $color-pink !important;
        color: #fefefe !important;
        font-size: 0.85em !important;

        @media only screen and (max-width: 770px) {
            width: 50% !important;
            margin-bottom: 10px !important;
        }

        &.ibe-reservation-footer-cancellation-btn {
            background-color: $color-pink-2 !important;
        }
    }
}

.ibe-reservation-info-row {
    display: flex;
    justify-content: space-between;
}

.ibe-reservation-nights-details {
    span {
        @media only screen and (max-width: 768px) {
            width: 40%;
        }
    }
    .ibe-reservation-stay-dates {
        width: inital !important;
    }
}

.up-ibe .ibe-reservation-extras {
    border-top: 0;
    background: $color-green-3;
    color: $color-body;

    .ibe-reservation-extras-wrap {
        border-left: 1px solid $color-body !important;

        @media only screen and (max-width: 767px) {
            border-left: none !important;
            border-top: 1px solid $color-body !important;
        }
    }

    .ibe-reservation-extra-name {
        text-transform: none !important;
    }
    .ibe-reservation-extra-price,
    .ibe-icon-tick {
        color: $color-body !important;
    }
    .ibe-remove-btn {
        background: $color-body !important;
        border-color: $color-body !important;

        .ibe-icon {
            color: #fff !important;
        }
    }
}
.ibe-add-extras-btn {
    margin-left: 0 !important;
    border-radius: 4rem;
}
.ibe-section-heading {
    margin-bottom: 1rem;

    a {
        color: $color-pink !important;

        &:hover {
            color: $color-body !important;
        }
    }
}
.ibe-reservation-detail-stay-dates {
    color: $color-body !important;
}
.ibe-reservation-booker-detail,
.ibe-reservation-payment-card {
    background: $color-green-3;
    border-radius: 0.5rem;
}
.ibe-reservation-booker-detail {
  padding: 1.5em !important;
}
.ibe-reservation-detail-stay-info {
  &.ibe-reservation-has-total-summary {
    .ibe-reservation-detail-total-area {
      ibe-total-summary {
        .ibe-total-cost {
          border-radius: 0;

          @media only screen and (min-width: 576px) {
            flex-wrap: wrap;
          }

          .ibe-total-cost-label {
            @media only screen and (max-width: 1200px) {
              font-size: 1.5em;
            }

            @media only screen and (max-width: 992px) {
              font-size: 1.2em;
            }
          }

          .ibe-vat-breakdown-btn {
            padding: 2em !important;
            border-radius: 0;

            @media only screen and (min-width: 1200px) {
              padding: 1em !important;
            }

            @media only screen and (max-width: 1200px) {
              padding: 1.5em 16px !important;
            }

            @media only screen and (max-width: 992px) {
              padding: 2em 1em !important;
            }

            @media only screen and (max-width: 768px) {
              padding: 2em !important;
            }

            @media only screen and (max-width: 576px) {
              padding: 0em .5em  !important;
            }
          }
        }
      }
    }
  }
}
.ibe-reservation-detail-stay-persons,
.ibe-resevation-detail-nights {
    svg {
        color: $color-dark-green;
    }
    span {
        color: $color-dark-green;
        font-weight: 700;
    }
}
.ibe-reservation-extras-name {
    color: $color-body !important;
    margin-left: 20px;
}
.ibe-resevation-detail-nights {
    span:last-child {
        color: $color-body;
        font-weight: 300;
    }
}
.up-ibe-extras {
    .mat-dialog-title {
        margin: 0 auto 1.5rem auto;
        color: $color-body !important;
        font-size: 1.5rem;
    }
    .mat-dialog-header {
        border-bottom: 1px solid $color-body !important;
    }
    .mat-dialog-container {
        max-width: 900px !important;
    }
}
.ibe-room-custom-content-description {
    padding: 0 3rem;

    h1,
    h2,
    h3,
    h4,
    li,
    p {
        color: $color-body;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        li {
            &:before {
                top: 5px;
            }
        }
    }
}
.ibe-room-custom-content-open-description {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
// sidebar only on London!
.ibe-wrapper {
    padding-bottom: 10em;
    overflow: visible;

    @media only screen and (max-width: 1300px) {
        overflow: hidden;
    }
    > .container {
        max-width: 80rem;
        margin: 0 auto;
        > p {
            width: 100%;
        }
        @media only screen and (max-width: 1300px) {
            flex-direction: column-reverse;

            .ibe-sidebar {
                margin-bottom: 3em;
                margin-left: 0;
                position: static;
                top: auto;
            }
        }
        @media only screen and (max-width: 990px) {
            flex-direction: column;
        }
    }
    &.results-list {
        > .container {
            display: flex;
            justify-content: center;
        }
        .ibe-sidebar {
            display: block;
        }
        .up-ibe .ibe-room-result .ibe-col-md-3:after {
            right: -1.75rem;
        }
    }
}
////////////////////////////////////////////////////////////////////////////////////
//My Qbic Page / Login Page Styling
////////////////////////////////////////////////////////////////////////////////////
.ibe-reservation-guest-management-detail {
    .ibe-reservation-detail-total-area {
        padding: 0!important;
        border: 0;
    }
    .ibe-total-cost {
        height: 3em;
        border-top: 2px solid $color-body !important;
        border-radius: 0;
    }
    .ibe-total-cost-value {
        margin: 0!important;
    }
    .ibe-total-cost-label {
        margin: 0!important;
        color: $color-body!important;
    }
    .ibe-vat-breakdown-btn {
        margin: 0!important;
        padding-right: 5px!important;
    }
    .ibe-reservation-detail-extras {
        color: $color-body!important;

        h4 {
            font-weight: 700 !important;
        }
        ul, li {
            overflow: auto!important;
        }
    }
    .ibe-reservation-detail-action-buttons {
        .ibe-download-invoice-btn, .ibe-cancel-rerservation-btn {
            border-radius: 8px!important;
        }
    }
    .ibe-reservation-detail-id {
        font-weight: bold!important;
    }
    .ibe-reservation-detail-total-summary {
        background: none!important;
        border: 2px solid $color-body!important;
        border-left: 0!important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        @include breakpoint(S) {
            border-top-right-radius: 0!important;
            border-top: 0!important;
            border-left: 2px solid $color-body!important;
        }
    }
    .ibe-reservation-detail-stay-info {
        border: 2px solid $color-body!important;
        background: none!important;

        @include breakpoint(S) {
            border-top: 0!important;
            border-bottom: 0!important;
        }
        h2 {
            color: $color-body!important;
            font-weight: 700 !important;
        }
        h5 {
            color: $color-body!important;
            text-transform: capitalize!important;
        }
        .ibe-reservation-detail-stay-price {
            margin: 0;
            p {
                margin: 0;
            }
            a {
                color: $color-body!important;
            }
        }
    }
    .ibe-reservation-detail-stay {
        p {
            margin: 0;
            color: $color-body !important;

            span {
                text-transform: capitalize!important;
            }
        }
    }
    .ibe-reservation-detail-status {
        color: $color-body!important;
    }
    .ibe-reservation-detail-pay-now-option {
        color: $color-body!important;
    }
    .ibe-section-heading {
        h4 {
            color: $color-body!important;
            text-transform: capitalize!important;
        }
    }
    .ibe-reservation-detail-unit-image {
        border: 2px solid $color-body;
        border-right: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        @include breakpoint(S) {
            border-bottom-left-radius: 0!important;
            div {
                border-bottom-left-radius: 0!important;
            }
            border-right: 2px solid $color-body;
        }
        div {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
    .ibe-reservation-payment-card {
        .ibe-pay-btn {
            border-radius: 30em;
        }
    }
    .ibe-vat-breakdown-btn {
        background: $color-pink !important;
        padding: 0.5rem !important;
        color: #fff !important;
        @media screen and (max-width: 1200px) and (min-width: 600px) {
            height: 7px;
            color: transparent!important;
            padding: 0!important;
            border-bottom-right-radius: 8px;
            transform: translateY(-6px);
        }
        @media screen and (max-width: 1200px) and (min-width: 600px) {
            transform: translateY(0px);
        }
        @media screen and (max-width: 1300px) and (min-width: 600px) {
            font-size: 14px!important;
        }
    }
    .ibe-total-tax-breakdown {
        border: none!important;
        border-bottom: 2px $color-body solid!important;
        &.open  {
            transform: translateY(59px)!important;
        }
    }
}
.ibe-reservation-extras-price {
    overflow: visible!important;
}
////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////
//My Qbic Page / Login Page Styling
////////////////////////////////////////////////////////////////////////////////////
.ibe-container {
    .ibe-native-date-picker {
        .ibe-form-control {
            padding: 1.2em 0!important;
            padding-left: 1.5em!important;
            padding-bottom: 0!important;
            text-align: center!important;
        }
    }
    .ibe-guest-management-header {
        border-bottom: 2px solid $color-body;
        .ibe-manage-link {
            text-transform: capitalize!important;
            color: $color-body!important;
        }
        .ibe-active-link {
            text-transform: capitalize!important;
            color: $color-pink!important;
        }
    }

    .ibe-user-heading {
        span {
            color: $color-body!important;
        }
        a {
            background: $color-dark-green;
            border-radius: 60px;
            padding: 0.5em 1em;
            text-transform: capitalize!important;
            color: white;
            font-size: 14px;
        }
    }
    .ibe-guest-management-edit-details {
        .ibe-btn {
            border-radius: 4rem;
        }
    }
    .ibe-guest-management-bookings-header, .ibe-guest-management-edit-details-header {
        svg {
            color: $color-body!important;
            border-color: $color-body!important;
            border-width: 2px!important;
            width: 6em!important;
            height: 6em!important;
            flex-shrink: 0;
        }
        h1 {
            text-transform: capitalize!important;
            color: $color-body!important;
        }
        p {
            color: $color-body!important;
            font-weight: 100!important;
            font-size: 16px!important;
            text-align: start!important;
        }
    }

    .ibe-reservation-status {
        text-transform: capitalize!important;
        background: $color-pink-3!important;
        border-radius: 8px;
        margin-bottom: 1em!important;
        .ibe-form-group {
            margin: 0!important;
        }
        select.ibe-custom-select, label[for="reservationStatusSelect"] {
            text-transform: capitalize!important;
            color: $color-pink!important;
        }
        select.ibe-custom-select {
            width: 7em!important
        }

    }

    .ibe-guest-management-bookings-table {
        table {
            margin: 0!important;

            tbody {
                tr {
                    padding: 0!important;
                    border: none!important;
                    background: none!important;
                    text-align: center!important;
                }
                th {
                    padding: 10px 15px!important;
                    margin-top: 0em!important;
                    border: none!important;
                    background: $color-green-3 !important;
                    text-align: center!important;
                }
                tr td {
                    padding: 0px 5px!important;
                    margin-top: 0em!important;
                    border: none!important;
                    background: none!important;
                    text-align: center!important;
                }
            }
        }

      .ibe-guest-reservations {
        .ibe-guest-reservation-status {
          margin: 0;
        }

        .ibe-reservation-details {
          padding-bottom: 1em !important;
        }

        .ibe-reservation-extras {
          &.ibe-mobile {
            padding: 1em !important;

            .ibe-reservation-extras-title {
              .ibe-reservation-cost {
                .ibe-total-cost-value {
                  font-size: 1.3em;
                }
              }
            }

            .ibe-reservation-extras-wrap {
              border-top: 0 !important;

              .ibe-reservation-extras-list {
                li {
                  &::before {
                    display: none;
                  }

                  span {
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .ibe-reservation-footer {
          .ibe-reservation-footer-links.ibe-reservation-action-btn {
            color: #fff !important;
            &.ibe-reservation-actions-open {
              color: #8a1657 !important;
            }
          }
          .ibe-reservation-actions {
            border: 1px solid #215732 !important;

            &.ibe-reservation-actions-open {
              transform: translateY(110px);

              &.ibe-reservation-isFlexible {
                transform: translateY(150px);
              }
            }

            li {
              margin: 0;
              &::before {
                display: none;
              }
              a {
                padding: 1em .5em !important;
              }
            }
          }
        }
      }
    }

    .ibe-edit-account-form {
        > h2 {
            font-family: Boing!important;
            font-style: normal!important;
            font-weight: 700!important;
            font-size: 32px!important;
            line-height: 40px!important;
            margin-top: 3em!important;
            margin-bottom: 1em!important;
            text-transform: capitalize!important;
            color: $color-body!important;
        }
    }

    .ibe-edit-account-form-section, .ibe-edit-account-form-section {
        border-radius: 4px;
        background: $color-body-cream!important;
        border: 2px solid $color-body!important;
        padding: 2em!important;
        label {
            font-weight: 700;
            color: $color-body!important;
        }
        input[type=checkbox] {
            margin-right: 0.5rem;
        }
        input, select, textarea {
            background: none!important;
            border: none!important;
            border-bottom: 2px $color-body solid!important;
            color: $color-pink !important;
            padding-left: 0;
            padding-right: 0;
        }
        .ibe-custom-select {
            color: $color-pink !important;
            position: relative;
            background: url(/wp-content/themes/qbic/autoload-svgs/arrow-down-green.svg) no-repeat right transparent!important;
        }
        .ibe-btn {
            border-radius: 8px;
        }
    }

    .ibe-guest-reservations {
        border: 1px solid $color-body !important;
        border-radius: 8px !important;
    }

    .ibe-guest-management-sidebar-option {
        margin-bottom: 1em;
        border-bottom: 1px solid $color-body!important;
        p {
            text-align: start!important;
            font-weight: normal;
            color: #111 !important;
            margin-bottom: 0;
        }
        h2 {
            margin: 0;
            a {
                color: $color-body!important;
            }
        }
    }
    .ibe-guest-management-options {
        align-items: unset;
    }
    .ibe-guest-management-option {
        border-radius: 8px;
        background-color: $color-green-3;
        position: relative;
        padding: 2rem;
        border: 0 !important;

        @include breakpoint(S) {
            padding: 1em!important;
            margin-top: 1em!important;
            margin-bottom: 0.5em!important;
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%);
            border-radius: 50%;
            height: 5.5em;
            width: 5,5em;
            background-color: $color-body-cream;
        }
        .ibe-guest-management-icon {
            background: $color-body-cream;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            height: 6rem;
            margin-right: 2.5rem;
            flex-shrink: 0;

            svg {
                color: $color-body;
                margin: 0 !important;
            }
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                margin-bottom: 1em;
                color: $color-body;
            }
            p, a {
                margin: 0!important;
                color: $color-body;
            }
        }
    }
}
.ibe-thank-you-message {
    &:after {
        content: ". You'll get a confirmation by email.";
    }
}
.ibe-main-heading {
    display: flex;
    span {
        margin: auto;
        text-transform: capitalize!important;
    }
}
.ibe-booking-login-form-box {
    border-radius: 8px!important;
    border: 2px $color-body solid!important;
}
.ibe-guest-details-dialog-title {
    font-weight: 700 !important;
    color: $color-body !important;
}
.ibe-form-group {
    label {
        text-transform: none !important;
    }
}
.up-ibe-guest-details, .ibe-booking-login-form-box {
    form {
        overflow-y: auto!important;
        border: 0!important;

        label {
            margin: 0em!important;
            font-weight: 700;
            color: $color-body!important;
        }
        input[type=checkbox] {
            margin-right: 0.5rem;
        }
        input, select, textarea, .ibe-form-control[_ngcontent-vaf-c10] {
            background: none!important;
            border: none!important;
            border-bottom: 2px $color-body solid!important;
            color: $color-pink !important;
            padding-left: 0;
            padding-right: 0;
            height: auto;
            min-height: unset!important;
            color: $color-pink !important;
            margin: 0;
        }
        .ibe-custom-select {
            color: $color-pink !important;
            position: relative;
            background: url(/wp-content/themes/qbic/autoload-svgs/arrow-down-green.svg) no-repeat right transparent!important;
        }
        .ibe-btn {
            border-radius: 4em;
        }
        .ibe-form-group {
            .ibe-form-control,
            .ibe-custom-select {
                color: $color-pink !important;
            }
        }
    }
    .mat-dialog-container {
        position: relative;

        .ibe-cancel-btn {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background: $color-error;
            border: 2px solid $color-error;
            color: #fff;

            &:hover {
                background: rgba($color-error, 0.75);
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .ibe-steps-bar .ibe-step-title {
        display: none !important;
    }
}
.booking-confirmation {
    .ibe-confirmation-header {
        text-align: center;
        position: relative;

        .ibe-print-btn {
            position: absolute;
            right: 0;
            top: calc(100% + 4rem);
        }
    }
    .ibe-confirmation-heading {
        margin: 0;
    }
    .ibe-thank-you-message {
        margin: 0.5rem auto 1rem auto;
        display: block;
    }
    .ibe-confirmation-heading,
    .ibe-thank-you-message {
        text-transform: none;
        text-align: center;
        color: $color-body !important;
    }
    .ibe-booking-id-box {
        background: none !important;
        text-align: left;
        padding: 0;
        margin: 2rem 0;
        text-transform: none;

        span:first-child {
            color: $color-body;

            &:after {
                content: ":";
            }
        }
        .ibe-booking-id {
            color: $color-pink !important;
        }
    }
    .ibe-confirmation-bottom {
        display: block;
    }
    .ibe-map {
        margin-bottom: 0;

        .lockup-carousel-wrapper {
            border: 2px solid $color-body;
            border-radius: 4px;

            img {
                width: 100%;
            }
        }
        .blob {
            z-index: -1;
        }
        p {
            color: $color-body;
        }
    }
}

.ibe-sidebar {
    width: 22rem;
    margin-top: 5rem;
    margin-left: 3.25rem;
    .green-box {
        .section:first-child {
            margin-bottom: 1.5em;
        }
    }
    @media only screen and (max-width: 1300px) {
        align-items: center;
        margin: auto;
        width: 100%;
        .ibe-sidebar-inner, .green-box {
            display: flex;
            width: auto;
            align-items: flex-start;
        }
        .green-box {
            margin: 0 2em;
            padding: 1em 2em;
            padding-bottom: 0;
            .section {
                width: 50%;
                &:first-child {
                    min-width: 16em;
                    padding-right: 1em;
                }
                &:last-child {
                    padding-left: 1em;
                }
            }
        }
    }
    @media only screen and (max-width: 990px) {
        .ibe-sidebar-inner, .green-box {
            flex-direction: column;
        }
        .green-box {
            margin: 0;
            padding: 2em;
            li {
                margin:0;
                padding: 0;
                text-indent: 0;
                &::before {
                    display: none;
                }
            }
            .section {
                padding: 0!important;
            }
        }
    }
    .indent {
        padding-left: 1rem;
    }
    .ta-rating {
        margin-bottom: 1rem;
        justify-content: flex-start;
    }
    .ibe-sidebar-inner {
        position: sticky;
        top: 5rem;
    }
}

html[lang="en"] {
    .up-ibe {
        label[for="bookerCommentInput"] {
            font-size: 0;
            &::after {
                content: "Special request?";
                color: #215732 !important;
                font-family: "Boing","Avenir","system-ui","Segoe UI","Roboto","Helvetica","Arial",sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.myqbic-logged-in {
    max-width: 62rem;
    margin: 2rem auto 3rem auto;
}

.gpay-button.black.long {
    background-repeat: no-repeat;
    background-position: center;
}

.blob-nights {
    position: relative;
    text-align: center;
    padding-bottom: 8rem;

    @media only screen and (max-width: 1400px) {
        display: none;
    }
    p {
        padding-left: 1rem;
        color: $color-body-cream;
        font-weight: 700;
        line-height: 1.5rem;
        font-size: 1.333rem;
        margin: 0;

        span {
            font-size: 2rem;
            padding-top: 0.25rem;
            color: $color-dark-green;
        }
    }
    svg {
        position: absolute;
        width: 100%;
        height: 16rem;
        right: 0;
        top: -6rem;
        z-index: -1;
    }
}

.site-2 {
    .ibe-room-pms-content-description {
        color: $color-body!important;
        column-count: 2;
        break-inside: avoid;

        ul {
            li {
                margin-bottom: 0.5em;
                &:before {
                    top: 5px;
                }
            }
        }

        @include breakpoint(M) {
            margin-bottom: 5em!important;
        }
    }
}
