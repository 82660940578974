.rel-posts {
    background: $color-body-cream;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;

    &.no-margin {
        margin-bottom: 0;
    }
    .card {
        flex-grow: 1;
        background: $color-pink-3;

        @include breakpoint(XS) {
            margin: 1em;
        }
    }
    h2 {
        @include breakpoint(XS) {
            font-size: 1.5em;
            padding-right: 4em;
        }
    }
    .card-grid {
        @include breakpoint(XS) {
            margin-left: -1.75rem;
            margin-right: -1.75rem;
        }
    }
    .slick-next,
    .slick-prev {
        transform: none;
        top: -4rem;
        position: absolute;
    }
    .slick-next {
        left: auto;
        right: 1rem;
    }
    .slick-prev {
        left: auto;
        right: 4.5rem;
    }
}

.cta-blocks {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint(S) {
        margin-left: -1.875em;
        margin-right: -1.875em;
    }
    &.cta-blocks-carousel {
        @include breakpoint(S) {
            margin-left: 0;
            margin-right: 0;
        }
        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                height: auto;
            }
        }
        .cta {
            width: 100%;

            @include breakpoint(XS) {
                width: calc(100% - 1em);
                margin: 0 0.5em;
            }
        }
        .slick-track,
        .slick-list {
            width: 100%;
        }
    }
}

.cta {
    margin: 0.5em;
    min-height: 22em;
    width: calc(33.333% - 1em);
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    @include breakpoint(XS) {
        width: 100%;
        margin: 0.5em 0;
        min-height: 20em;
    }
    &.full-width {
        width: 100%;
        margin: 0.5em 0;
    }
    &.half {
        width: calc(50% - 1.01em);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.quarter {
        width: calc(25% - 1.01em);

        @include breakpoint(S) {
            width: calc(50% - 1.01em);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.short {
        height: 20em;

        .cta-inner {
            padding: 0;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @extend %nav-transition;
    }
    &:before {
        content: "";
        background: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        @extend %transition;
    }
    .cta-inner {
        position: relative;
        z-index: 3;
        color: $color-body-cream;
        text-align: center;
        width: 85%;
        padding: 1em;

        @include breakpoint(XS) {
            width: 100% !important;
        }
    }
    h3 {
        max-width: 12em;
        margin: 0 auto 0.5rem auto;
    }
    svg {
        @extend %nav-transition;
    }
    p {
        max-width: 28em;
        margin: 0 auto 0.5rem auto;

        &:last-child {
            margin-bottom: 0;
        }
        @include breakpoint(M) {
            max-width: 80%;
        }
        @include breakpoint(XS) {
            max-width: 100%;
            width: 100%;
        }
    }
    .cta-excerpt {
        margin-bottom: 1rem;

        p {
            margin: 0 auto;
            font-size: 0.75em;
        }
    }
    .date {
        margin-bottom: 0.5rem;
    }
    &.has-tagline {
        padding-top: 3em;

        @include breakpoint(XS) {
            min-height: 22em;
            padding-top: 1em;
        }
    }
    .tagline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 2.5rem;
        text-align: center;
        z-index: 3;
        color: $color-body-cream;

        &:before {
            content: "";
            position: absolute;
            width: 1px;
            left: 50%;
            height: 2em;
            background: $color-body-cream;
            top: 0;
        }
    }
    .button {
        min-width: 0;
        position: absolute;
        bottom: 1.5em;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);

        svg {
            display: block;
        }
    }
    &:hover {
        img {
            transform: scale(1.1) rotate(3deg);
        }
        &:before {
            opacity: 0.7;
        }
        .button {
            border: 2px solid $color-mid-green;
            background: $color-mid-green;
        }
        svg {
            transform: translateX(0.25em);
        }
    }
}

.cta-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.75em;
    margin-right: -0.75em;

    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
    .cta {
        min-height: 22em;
        flex-grow: 0;

        .diamond {
            margin-bottom: 0;
        }
        .cta-inner {
            width: 100%;
        }
        &.home-tab-target {
            display: none;
            opacity: 0;

            &.active {
                display: flex;
                opacity: 1;
            }
            &.transparent {
                display: flex;
                opacity: 0;
            }
        }
    }
    &.feat-first {
        display: block;

        .cta {
            float: left;
            height: 18em;
            min-height: 0;
            
            &:first-child {
                width: calc(50% - 1em);
                height: calc(37em - 0.5px);

                @include breakpoint(XS) {
                    width: 100%;
                    height: 18em;

                    .diamond {
                        display: none;
                    }
                    h2 {
                        margin: 0;
                        font-size: 1.357em;
                        line-height: 1.289473684210526;
                    }
                }
            }
        }
    }
}

.loadmore-wrapper {
    text-align: center;
    padding-top: 3em;
}

.blog {
    .posts-list .cta-grid .cta {
        .cta-inner {
            padding: 0.5em;

            @include breakpoint(XS) {
                padding: 0.5em 2em;
            }
        }
        h4 {
            line-height: 1.125;
            margin-bottom: 0.25em;
        }
        h6 {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;

            .divider:last-child {
                display: none;
            }
        }
        .button {
            padding: 0.5em;

            svg {
                width: 0.75em;
                height: 0.75em;
            }
        }
    }
}

.category-target {
    @extend %transition;

    &.filtered {
        display: none;
    }
    &.fade-out {
        opacity: 0;
    }
}

.single-details {
    .divider:last-child {
        display: none;
    }
}

.share {
    font-weight: 700;
    display: flex;
    margin-bottom: 2rem;
    
    &:last-child {
        margin-bottom: 0;
    }
    p {
        margin: 0;
    }
    p:first-child {
        padding-right: 0.25em;
    }
    .share-links {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            display: block;

            path {
                @extend %transition;
            }
        }
        a {
            margin: 0 0.25em;

            &:hover {
                svg {
                    path {
                        fill: $color-mid-green;
                    }
                }
            }
        }
    }
}

.posts-list {
    margin-bottom: 5em;
}

.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.75em;
    margin-right: -0.75em;

    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
}

.card {
    background: $color-body-cream;
    box-shadow: 0 2px 12px 0 rgba($color-body,0.25);
    margin: 0.75em;
    min-height: 25em;
    width: calc(33.333% - 1.51em);
    position: relative;
    
    @include breakpoint(S) {
        width: calc(50% - 1.51em);
    }
    @include breakpoint(XS) {
        width: 100%;
        margin: 0.75em 0;
        min-height: 20em;
    }
    &.half {
        width: calc(50% - 1.51em);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.quarter {
        width: calc(25% - 1.51em);

        @include breakpoint(S) {
            width: calc(50% - 1.51em);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .card-img {
        height: 18em;
        position: relative;
        display: block;
        overflow: hidden;
        transition: none;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @extend %transition;

            &:hover {
                filter: grayscale(100%);
            }
        }
    }
    .card-content {
        padding: 2.5em 2em 5em 2em;

        * {
            max-width: 100%;
        }
    }
    h3 {
        margin-bottom: 0.5rem;
    }
    .card-mini-links {
        margin-bottom: 0.5rem;
        font-size: 0.875em;

        p {
            display: inline;
            margin: 0 2em 0 0;
        }
        span,
        a {
            opacity: 0.5;
        }
        .divider:last-child {
            display: none;
        }
        a {
            font-weight: normal;
            color: $color-body;

            &:hover {
                opacity: 1;
                color: $color-dark-green;
            }
        }
    }
    h2,
    h3 {
        a {
            color: $color-body;

            &:hover {
                color: $color-dark-green;
            }
        }
    }
    .button {
        position: absolute;
        left: 2em;
        bottom: 2em;
    }
    .tagline {
        font-weight: 700;
        color: $color-dark-green;
    }
}

.feat-card {
    width: calc(100% - 1.51em);
    display: flex;

    .tagline,
    .card-mini-links,
    h2,
    h3 {
        margin-bottom: 1rem;
    }
    .card-img {
        height: auto;
        width: 50%;

        @include breakpoint(XS) {
            width: 100%;
            height: 18em;
        }
    }
    .card-content {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.5em 2em;

        @include breakpoint(XS) {
            width: 100%;
            display: block;
        }
    }
    .button {
        position: static;
    }
    @include breakpoint(XS) {
        display: block;
        width: 100%;
    }
}