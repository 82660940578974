.builder-elements {
    padding-bottom: 0;
    overflow: hidden;
    width: 100%;

    @include breakpoint(XS) {
        padding-bottom: 0rem;
    }
    &.no-padd {
        padding-bottom: 0;

        @include breakpoint(XS) {
            padding-bottom: 0;
        }
    }
    .row {
        margin-bottom: 5rem;
        position: relative;

        &.pink-bg {
            background: $color-pink-3;
        }
        &.green-bg {
            background: $color-green-3;
        }
        &:last-child.last-no-margin,
        &.no-margin {
            margin-bottom: 0;
        }
        &:first-child {
            .img-content {
                padding-top: 3rem;
            }
        }
    }
}

.content-element {
    text-align: center;
    max-width: 62em;
    margin: 0 auto;

    @include breakpoint(S) {
        position: relative;
        z-index: 3;
    }
    p:last-child {
        margin-bottom: 0;
    }
    p + a {
        margin-bottom: 1.5rem;
    }
    .dropdown {
        @include breakpoint(S) {
            margin-bottom: 1em;
        }
    }
}

.cta-grid-wrapper {

    .content-element {
    
        .subtitle, 
        h4 {
            margin-bottom: 1.5rem;
        }
    }
}

.icon-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  
    &.thirds {
        li {
            width: 33.333%;

            @include breakpoint(S) {
                width: 50%;
            }
        }
    }
    li {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 1rem 1rem 0;
        width: 50%;
    
        @include breakpoint(XS) {
            width: 12em;
        }
        &:before {
            display: none;
        }
        svg {
            width: 1.75em;
            height: 1.75em;
            margin-right: 1em;
            flex-shrink: 0;

            path,
            polygon,
            rect,
            line {
                stroke: $color-body;
            }
        }
        .icon-img {
            margin-right: 2em;
            float: left;
            height: 4.5em;
            width: 4.5em;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            svg,
            img {
                height: 4.5em;
                width: 4.5em;
                margin: 0;
            }
            @include breakpoint(S) {
                height: 4.5em;
                width: 4.5em;
            }
            @include breakpoint(XS) {
                height: 3.5em;
                width: 3.5em;
            }
        }
        h5 {
            margin: 0;
        }
        p {
            font-size: 0.875em;
            margin: 0;
            line-height: 1.5;

            @include breakpoint(XS) {
                font-size: 0.666rem;
            }
        }
    }
    &.carousel {
        overflow: hidden;
        display: block;
        padding-left: 2em;
        padding-right: 2em;
    
        @include breakpoint(XS) {
            padding-left: 0;
            padding-right: 0;
            margin-left: -1.75rem;
            margin-right: -1.75rem;
            padding-bottom: 2rem;

            .slider-prev,
            .slider-next {
                top: auto;
                bottom: -2em;
            }
            .slider-prev {
                left: 30%;
            }
            .slider-next {
                right: 30%;
            }
        }
        &.no-container {
            @include breakpoint(XS) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        li {
            padding: 2em;
    
            @include breakpoint(XS) {
                padding: 2em 1em;
                opacity: 0.25;

                &.slick-active,
                &.slick-center {
                    opacity: 1;
                }
            }
            .icon-img {
                float: none;
                margin: 0 auto 1em auto;
    
                @include breakpoint(S) {
                    margin: 0 auto 2em auto;
                }
            }
            p,
            svg {
                margin: 0;
            }
            .icon-content {
                text-align: center;
            }
        }
        .slick-arrow {
            padding: 0;
        }
    }
}

.icon-list-pager {
    text-align: center;
    font-weight: 700;
    margin-top: -1.25em;
    display: none;

    @include breakpoint(XS) {
        display: block;
    }
    span {
        margin: 0 0.25em;
    }
}

.lockup {
    position: relative;
    margin: 0 auto;
    
    .lockup-img {
        position: relative;
    }
    svg {
        display: block;
        min-height: 20em;
    }

    &.quentin {
        max-width: 28em;

        .lockup-img {
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-15.svg);
            mask-size: 100% 100%;
            transform: rotate(5deg);

            img {
                transform: rotate(-5deg);
            }
        }
        .blob-1 {
            position: absolute;
            bottom: -2em;
            right: 0;
            width: 12em;
            height: auto;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.mildred {
        max-width: 28em;

        .lockup-img {
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-01.svg);
            mask-size: 100% 100%;
            z-index: 2;
        }
        .blob-1 {
            position: absolute;
            bottom: -2em;
            right: -2em;
            width: 100%;
            height: auto;
            z-index: 1;

            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            bottom: 2em;
            right: 0;
            width: 10em;
            z-index: 3;
            transform: rotate(-15deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.inga {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
        }
        .blob-1 {
            position: absolute;
            top: -6em;
            right: -2em;
            width: 90%;
            height: auto;
            z-index: 1;
            transform: rotate(-90deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            bottom: -4em;
            left: -2em;
            width: 14em;
            z-index: 3;
            transform: rotate(180deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.deandra {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-16.svg);
            mask-size: 100% 100%;
        }
        .blob-1 {
            position: absolute;
            top: 0;
            right: -2em;
            width: 90%;
            height: auto;
            z-index: 1;
            transform: rotate(-90deg);
            
            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            opacity: 0.5;
            position: absolute;
            top: -4em;
            right: 1em;
            z-index: 6;
            width: 12em;

            @supports (mix-blend-mode: multiply) {
                mix-blend-mode: multiply;
                opacity: 1;
            }
            svg {
                width: 100%;
                height: auto;
                z-index: 3;
                transform: rotate(-15deg);

                path {
                    mix-blend-mode: multiply;
                }
            }
        }
    }
    &.mary {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-02.svg);
            mask-size: 100% 100%;
        }
        .blob-1 {
            opacity: 0.5;
            position: absolute;
            top: -3em;
            right: -2em;
            width: 17em;
            z-index: 3;

            @supports (mix-blend-mode: multiply) {
                mix-blend-mode: multiply;
                opacity: 1;
            }
            svg {
                width: 100%;
                height: auto;
                transform: rotate(15deg);
            }
        }
        .blob-2 {
            position: absolute;
            top: 0;
            right: 1em;
            width: 85%;
            z-index: 1;
            transform: rotate(-150deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.martha {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-18.svg);
            mask-size: 100% 100%;
        }
        .blob-1 {
            position: absolute;
            top: -2em;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 1;
            transform: rotate(96deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            top: -2em;
            right: -5em;
            width: 16em;
            z-index: 3;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.jaden {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
        }
        .blob-1 {
            position: absolute;
            top: -6em;
            right: -2em;
            width: 70%;
            height: auto;
            z-index: 1;
            transform: rotate(-27deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            bottom: -6em;
            left: -2em;
            width: 16em;
            z-index: 3;
            transform: rotate(141deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.annie {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
        }
        .blob-1 {
            position: absolute;
            top: -6em;
            left: -5em;
            width: 130%;
            height: auto;
            z-index: 1;
            transform: rotate(-27deg);

            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            bottom: -6em;
            right: -2em;
            width: 14em;
            z-index: 3;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    &.clement {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-15.svg);
            mask-size: 100% 100%;
        }
        .blob-1 {
            position: absolute;
            top: 1em;
            right: -2em;
            width: 100%;
            z-index: 1;

            svg {
                width: 100%;
                height: auto;
                transform: rotate(15deg);
            }
        }
        .blob-2 {
            opacity: 0.5;
            position: absolute;
            top: 0;
            right: 1em;
            width: 12em;
            z-index: 3;

            @supports (mix-blend-mode: multiply) {
                mix-blend-mode: multiply;
                opacity: 1;
            }
            svg {
                width: 100%;
                height: auto;
                transform: rotate(-102deg);
            }
        }
    }
    &.tommy {
        max-width: 28em;

        .lockup-img {
            z-index: 2;
            mask: url(/wp-content/themes/qbic/autoload-blobs/shape-06.svg);
            mask-size: 100% 100%;
            transform: rotate(21deg);

            img {
                transform: rotate(-21deg);
            }
        }
        .blob-1 {
            position: absolute;
            z-index: 2;
            left: 0;
            width: 100%;
            height: 100%;
            top: 0;

            .inner-blob {
                position: absolute;
                z-index: 2;

                &:first-child {
                    top: 8em;
                    left: 1em;
                    width: 13em;
                    height: auto;
                    transform: rotate(-29deg);
                }
                &:last-child {
                    bottom: 9em;
                    right: -6em;
                    width: 16em;
                    height: auto;
                    transform: rotate(58deg);
                }
            }
            svg {
                width: 100%;
                height: auto;
            }
        }
        .blob-2 {
            position: absolute;
            bottom: -5em;
            left: 6em;
            width: 12em;
            z-index: 3;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
    
    &.blob-2-is-texture {
        .blob-2 {
            right: -2.5em;
        }
    }
}

.lockup-controls {
    position: absolute;
    bottom: -3em;
    left: -0.5em;
    display: flex;
    align-items: center;
    z-index: 4;
    font-weight: 700;

    .lockup-pager {
        text-align: center;
        min-width: 3.5em;

        span {
            margin: 0 0.25em;
        }
    }
    svg {
        display: block;
    }
    .lockup-prev,
    .lockup-next {
        cursor: pointer;
    }
}

.lockup-carousel-wrapper {
    position: relative;

    .lockup-carousel {
        position: relative;
        z-index: 2;
    }
    .lockup-carousel-captions {
        position: absolute;
        width: 12em;
        height: 12em;
        bottom: -5em;
        right: 2.5em;
        display: flex;
        align-items: center;
        z-index: 3;

        .slick-list,
        .slick-slide,
        .slick-track {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        @include breakpoint(XS) {
            right: 0;
        }
        p {
            margin: 0;
            text-align: center;
            position: absolute;
            z-index: 2;
            width: 12em;
            left: 0;
            padding: 0 1.5em;
            top: 50%;
            transform: translateY(-50%);
        }
        .no-caption {
            opacity: 0;
            visibility: hidden;
        }
    }
    .caption-bg {
        z-index: 2;
        position: absolute;
        width: 12em;
        height: 12em;
        bottom: -5em;
        right: 2.5em;
        transition: all 0.5s ease-out;

        @include breakpoint(XS) {
            right: 0;
        }
        &.hide {
            opacity: 0;
            visibility: hidden;
        }
    }
    .blob {
        position: absolute;
        right: -2em;
        width: 80%;
        top: -2em;
        z-index: 1;

        @include breakpoint(XS) {
            width: 100%;
        }
    }
}

.features-grid {
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-auto-rows: 10em;
    grid-auto-rows: minmax(auto, 8em);
    grid-row-gap: 1.5rem;
    margin-bottom: 6em;

    @include breakpoint(L) {
        grid-auto-rows: 7em;
    }
    .grid-item {
        display: block;
        position: relative;
        margin: 0.5em;
        width: calc(100% - 1em);
        grid-row-end: span 2;

        svg {
            display: block;
            @extend %nav-transition;
        }
        .item-inner {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1em 2em;

            &:before {
                content: "";
                background: rgba(0,0,0,0.4);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                @extend %transition;
            }
            &.is-blob {
                overflow: visible;

                &.light-text {
                    p {
                        color: $color-body-cream;
                    }
                }
                &:before {
                    display: none;
                }
                p {
                    color: $color-body;
                }
                .strapline {
                    border-bottom: 2px solid $color-body;
                }
                iframe {
                    width: 200px;
                    height: 60px;
                    margin-bottom: 1em;
                }
                .trustyou {
                    opacity: 0.5;
                }
                .blob {
                    position: absolute !important;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @extend %nav-transition;
            }
            p {
                position: relative;
                z-index: 2;
                color: $color-body-cream;
                margin: 0;

                &.buttons-title {
                    font-size: 1.3333em;
                    font-weight: 700;
                }
                &.small {
                    font-size: 0.777rem;
                    line-height: 1.5em;
                }
            }
            .small {
                font-size: 0.777rem;
                line-height: 1.5em;
            }
            .strapline {
                color: $color-body-cream;
                font-size: 1em;
                border-bottom: 2px solid $color-body-cream;

                a {
                    color: $color-body-cream;
                    display: block;

                    &:hover {
                        color: $color-dark-green;
                    }
                }
                span {
                    color: $color-dark-green;
                }
            }
            &.has-buttons {
                p {
                    margin-bottom: 1rem;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .cta-lockup {
            position: absolute;
            bottom: -1em;
            right: 0;
            width: 16em;
            height: 16em;
            z-index: 4;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            p {
                position: relative;
                z-index: 3;
                margin: 0;
                color: $color-body;

                svg {
                    margin: 0 auto;
                    display: block;
                }
            }
            .cta-lockup-blob {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
            &.subpage-lockup-1 {
                padding: 2em 4em 1em 1em;
            }
            &.subpage-lockup-2 {
                padding: 1em 1em 0 4em;
            }
            &.subpage-lockup-3 {
                padding: 1em 2em 0 4em;
            }
            &.subpage-lockup-4 {
                padding: 1em 2em 0 3em;
            }
            &.subpage-lockup-5 {
                padding: 1em 2em 0 1em;
            }
            &.subpage-lockup-6 {
                padding: 0 3em 0 2em;

                p {
                    color: $color-body-cream;

                    path {
                        stroke: $color-body-cream !important;
                    }
                }
            }
            &.subpage-lockup-7 {
                padding: 0 3em;

                p {
                    color: $color-body-cream;

                    path {
                        stroke: $color-body-cream !important;
                    }
                }
            }
        }
        &:hover {
            .item-inner img {
                transform: scale(1.1) rotate(3deg);
            }
            .item-inner:before {
                background: rgba(0,0,0,0.6);
            }
            svg {
                transform: translateX(0.25em);
            }
        }
    }
    &.elements-7 {
        .grid-item {
            &:first-child {
                grid-row-end: span 4;
                grid-column: 1 / 3;

                .item-inner {
                    mask: url(/wp-content/themes/qbic/images/square-grid-shape.svg);
                    mask-size: 100% 100%;
                }
            }
            &:nth-child(3) {
                .strapline {
                    color: $color-body;

                    a {
                        color: $color-body;

                        &:hover {
                            color: $color-dark-green;
                        }
                    }
                }
            }
            &:nth-child(5) {
                grid-row-end: span 4;

                .item-inner {
                    mask: url(/wp-content/themes/qbic/images/tall-grid-shape.svg);
                    mask-size: 100% 100%;
                    padding: 0 22%;
                }
            }
            &:nth-child(7) {
                grid-column: 2 / 4;
            }
        }
        @include breakpoint(S) {
            grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));

            .grid-item {
                &.grid-item:nth-child(5) {
                    grid-column: 3 / 4;
                }
                &.grid-item:nth-child(6) {
                    grid-column: 2 / 3;
                    grid-row: 5 / span 2;
                }
                &.grid-item:nth-child(7) {
                    grid-column: 1 / 3;
                }
            }
        }
        @include breakpoint(XS) {
            display: block;
            margin-left: -2em;
            margin-right: -2em;

            &:after {
                content: " ";
                display: table;
                clear: both;
            }
            .grid-item {
                height: 15em;
                margin: 0;
                width: calc(60% - 1em);

                &:nth-child(odd) {
                    clear: both;
                }
                &:nth-child(even) {
                    float: right;
                }
                &:nth-child(1) {
                    height: 17em;
                    width: calc(70% - 1em);
                    margin-bottom: 8em;

                    .cta-lockup {
                        bottom: -7em;
                        right: -5em;
                    }
                }
                &:nth-child(5) {
                    height: 28em;
                }
            }
        }
    }
    &.elements-5 {
        .grid-item {
            &:first-child {
                grid-column: 1 / 3;
            }
            &:nth-child(2) {
                grid-row-end: span 4;
                grid-column: 3 / 5;

                .item-inner {
                    mask: url(/wp-content/themes/qbic/images/square-grid-shape.svg);
                    mask-size: 100% 100%;
                    padding-left: 8%;
                    padding-right: 8%;
                }
                .cta-content {
                    max-width: 85%;
                }
            }
            &:nth-child(3) {
                grid-row-start: 3;
                grid-row-end: 7;
                grid-column: 1 / 3;

                .item-inner {
                    mask: url(/wp-content/themes/qbic/images/square-grid-shape.svg);
                    mask-size: 100% 100%;
                    padding-left: 8%;
                    padding-right: 8%;
                }
                .cta-content {
                    max-width: 85%;
                }
            }
            &:nth-child(4) {
                grid-column: 3;
            }
            &:nth-child(5) {
                grid-column: 4;
            }
        }
        @include breakpoint(S) {
            display: flex;
            flex-wrap: wrap;

            .grid-item {
                height: 15em;

                &:nth-child(2),
                &:nth-child(3) {
                    height: 42em;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    width: calc(50% - 1em);
                }
            }
        }
        @include breakpoint(XS) {
            display: block;

            .grid-item {
                height: auto;
                min-height: 12em;
                padding: 1em;

                .item-inner {
                    position: static;

                    &.is-blob {
                        position: absolute;
                    }
                }
                &:nth-child(2),
                &:nth-child(3) {
                    height: 20em;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    width: calc(100% - 1em);
                }
            }
        }
    }
}

.no-cssgrid {
    .features-grid {
        display: flex;
        flex-wrap: wrap;

        .grid-item {
            height: 20em;
            width: calc(50% - 1em);

            &:last-child {
                width: calc(100% - 1em);
            }
        }
    }
}

.grid-bg {
    position: absolute;
    left: -5em;
    top: 0;
    height: 100%;
    width: calc(50% + 10em);
    z-index: -1;

    @include breakpoint(S) {
        width: 100%;
    }
    &.right {
        left: auto;
        right: -10em;
    }
    svg {
        height: 100%;
        width: auto;
    }
    @include breakpoint(L) {
        &.texture-blob {
            left: 0;

            &.right {
                left: auto;
                right: 0;
            }
        }
    }
}

.blob-row-wrapper {
    padding: 3em 0 1.5em 0;
    position: relative;

    .content-element {
        max-width: 42em;
    }
    .blob-row-top {
        position: absolute;
        bottom: calc(100% - 2px);
        left: 0;
        width: 100%;
        overflow: hidden;

        svg {
            width: 100%;
            height: auto;
            min-width: 30em;
            display: block;
        }
    }
    .blob-row-bottom {
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        width: 100%;
        overflow: hidden;

        svg {
            width: 100%;
            height: auto;
            min-width: 30em;
            display: block;
        }
    }
    .slick-arrow {
        &:hover {
            transform: translateY(-50%);
        }
        &.slick-prev {
            left: 0.5rem;
        }
        &.slick-next {
            right: 0.5rem;
        }
    }
}

.builder-elements .blob-row-container.last-no-margin:last-child {
    margin-bottom: 12em;
}

.blob-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2em;

    .blob-row-single {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 16em;
        height: 16em;

        p {
            margin: 0;
            position: relative;
            z-index: 1;
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 1.3333em;
            color: $color-body;

            &.small {
                font-size: 1rem;
                max-width: 12rem;
            }
        }
        h2,
        h3,
        h4 {
            position: relative;
            z-index: 1;
        }
        &.is-green {
            p {
                color: $color-body-cream;
            }
        }
        &.is-link {
            &:hover {
                transform: translateY(-1rem);
            }
        }
        .blob {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .top-icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -35%);
            z-index: 2;
        }
    }
}

.blob-row-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 16em;
    height: 16em;
    mask-size: 100% 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;
    @extend %nav-transition;

    &.is-green {
        h3,
        h4,
        p {
            color: $color-body-cream;
        }
    }
    @include breakpoint(M) {
        width: 14em;
        height: 14em;
    }
    @include breakpoint(S) {
        margin-top: 0;
        margin-bottom: 0;
        width: 18em;
        height: 18em;
    }
    @include breakpoint(XS) {
        width: 18em !important;
        height: 18em !important;
        margin-top: 4rem;
        margin-bottom: 4rem;

        &.slick-slide {
            width: 12em !important;
            height: 12em !important;

            &.slick-active {
                width: 18em !important;
                height: 18em !important;
                margin-top: 0;
                margin-bottom: 0;

                .blob-front {
                    opacity: 0;
                    z-index: -1;
                }
                .blob-inside {
                    opacity: 1;
                    z-index: 1;
                }
                h4,
                p,
                svg {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
    .blob-front,
    .blob-inside,
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @extend %nav-transition;
    }
    .blob-front,
    .blob-inside {
        padding: 2rem;
    }
    .blob-inside {
        opacity: 0;
        z-index: -1;

        h4,
        p,
        svg {
            position: relative;
            z-index: 3;
            color: #fff;
            opacity: 0;
            transform: translateY(-0.5rem);
            @extend %nav-transition;
        }
        p {
            font-size: 0.875rem;
            line-height: 1.3;
            font-weight: normal;
        }
        img {
            z-index: 0;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(#000, 0.5);
        }
    }
    &.hovering {
        width: 12rem;
        height: 12rem;

        &:hover {
            width: 24rem;
            height: 24rem;
        }
    }
    &:hover {
        width: 24rem;
        height: 24rem;
        margin-top: 0;
        margin-bottom: 0;

        &.smaller-blob {
            width: 20rem;
            height: 20rem;
        }
        .blob-front {
            opacity: 0;
            z-index: -1;
        }
        .blob-inside {
            opacity: 1;
            z-index: 1;
        }
        h4,
        p,
        svg {
            opacity: 1;
            transform: translateY(0);
        }
        h4 {
            transition-delay: 0.3s;
        }
        p {
            transition-delay: 0.5s;
        }
        svg {
            transition-delay: 0.7s;
        }
    }
}

.motley-theme {
    .container {
        @include breakpoint(S) {
            padding-left: 4.5em;
            padding-right: 4.5em;
        }
        @include breakpoint(XS) {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
    }
    .content-element {
        margin-bottom: 8rem;

        @include breakpoint(XS) {
            margin-bottom: 6rem;
        }
    }
    .img-content {
        padding-bottom: 0;
        margin-bottom: 5rem;

        .blob {
            position: absolute;
            top: 0;
            z-index: -1;

            svg {
                width: 25em;
                height: 25em;
            }
        }
        .lockup-controls {
            flex-direction: column;
            bottom: 0;

            @include breakpoint(XS) {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                bottom: -5em;
            }
        }
        .lockup-prev {
            @include breakpoint(XS) {
                order: 1;
            }
        }
        .lockup-next {
            margin-top: 0.5em;

            @include breakpoint(XS) {
                margin-top: 0;
                order: 3;
            }
        }
        .img {
            @include breakpoint(XS) {
                margin-right: -3em;
                margin-left: -3em;
                margin-bottom: 7.5em;
            }
        }
        .lockup-pager {
            font-size: 0.777em;
            margin-top: 1rem;
            position: relative;
            width: 2.5em;
            min-width: 0;

            @include breakpoint(XS) {
                margin: 0 1em;
                order: 2;
            }
            span {
                margin: 0;
                display: block;

                &.slide-no {
                    text-align: left;
                }
                &.total-slides {
                    text-align: right;
                }
            }
            .divider {
                position: absolute;
                height: 1px;
                width: 100%;
                top: 0.5em;
                right: 0.5em;
                transform: rotate(-45deg);
                background: $color-body;
                transform-origin: top right;
            }
        }
        .lockup-carousel-wrapper {
            .lockup-carousel-captions {
                height: 5em;
                width: 100%;
                bottom: -4em;

                p {
                    font-size: 0.777em;
                    padding: 0;
                    width: 100%;
                }
            }
        }
        &.text-image {
            .blob {
                left: 0;
            }
            .img {
                margin-right: -3em;
            }
            .lockup-controls {
                left: auto;
                right: -2.75em;

                @include breakpoint(XS) {
                    left: 0;
                    right: 0;
                }
            }
            .lockup-carousel-wrapper {
                .lockup-carousel-captions {
                    right: auto;
                    left: 0;
                    
                    p {
                        text-align: left;

                        @include breakpoint(XS) {
                            text-align: center;
                        }
                    }
                }
            }
        }
        &.image-text {
            .blob {
                right: 0;
            }
            .img {
                margin-left: -3em;
            }
            .lockup-controls {
                left: -2.75em;

                @include breakpoint(XS) {
                    left: 0;
                    right: 0;
                }
            }
            .lockup-carousel-wrapper {
                .lockup-carousel-captions {
                    right: 0;
                    
                    p {
                        text-align: right;

                        @include breakpoint(XS) {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .motley-footer {
        .container-inner {
            padding-top: 3em;
            padding-bottom: 3em;

            @include breakpoint(XS) {
                p {
                    font-size: 0.777em;
                }
            }
        }
    }
    &.motley-qbic {
        background: $color-body;
        padding-top: 2rem;
        padding-bottom: 2rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li,
        p,
        .img-content .lockup-pager {
            color: #fff;
        }
        .img-content .blob {
            z-index: 0;

            @include breakpoint(XS) {
                display: none;
            }
        }
        .img-content .lockup-pager .divider {
            background: #fff;
        }
        .img-content .lockup-carousel-wrapper .lockup-carousel-captions {
            bottom: -5rem;
        }
        .img-content {
            margin-bottom: 0;
            padding-bottom: 1.5rem;
            padding-top: 1.5rem;

            @include breakpoint(XS) {
                .content .content-inner .button {
                    width: auto;
                }
            }
        }
        .container-inner {
            border: 2px solid #fff;
        }
    }
}

.menus {
    display: flex;
    text-align: center;
    border-top: 2px solid $color-body;
    border-bottom: 2px solid $color-body;

    @include breakpoint(XS) {
        border-bottom: 0;
    }
    &:hover {
        .menu {
            opacity: 0.5;

            &:hover {
                opacity: 1;

                .button {
                    background: $color-body;
                    color: $color-body-cream;

                    &:hover {
                        color: $color-body;
                        border-top: 2px solid $color-body;
                        background: $color-body-cream;
                    }
                }
            }
        }
    }
    .menu {
        flex-grow: 1;
        flex-basis: 0;
        border-right: 2px solid $color-body;
        padding-top: 1.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        @extend %transition;

        &.menu-modal-trigger {
            cursor: pointer;
        }
        &:hover {
            background: $color-body-cream;
        }
        > img {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            @extend %transition;
        }
        .menu-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 0 1em 3em 1em;
        }
        &:last-child {
            border: 0;
        }
        p {
            font-size: 0.777em;
            margin-bottom: 1rem;
        }
        .button {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border: 0;
            border-top: 2px solid $color-body;
            font-size: 0.777em;
            padding: 1rem 0.5rem;

            &:hover {
                transform: none;
            }
        }
        h5 {
            position: relative;

            > svg {
                display: none;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 1em;
                @extend %nav-transition;
            }
        }
    }
    &.collapse-mobile {
        @include breakpoint(S) {
            display: block;

            &:hover {
                .menu {
                    opacity: 1;
                }
            }
        }
        .menu {
            @include breakpoint(S) {
                &:hover {
                    background: none;
                }
                width: 100%;
                border-right: 0;
                border-bottom: 2px solid $color-body;
    
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: rgba(0,0,0,0.6);
                    opacity: 0;
                    visibility: hidden;
                }
            }
            h5 {
                @include breakpoint(S) {
                    padding: 0 2.5em;
                }
                > svg {
                    @include breakpoint(S) {
                        display: block;
                    }
                }
            }
            .button {
                @include breakpoint(S) {
                    position: static;
                    border: 2px solid $color-body-cream;
                    color: $color-body-cream;
                    width: auto;
                    padding: 0.675em 1.75em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            > img {
                @include breakpoint(S) {
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .menu-content {
                @include breakpoint(S) {
                    display: none;
                    padding-bottom: 1em;
                    color: $color-body-cream;
                    margin: 0 auto;
                }
            }
            &.open {
                @include breakpoint(S) {
                    h5 {
                        color: $color-green-2;
    
                        svg {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                    > img,
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &.in-restaurant {
        @include breakpoint(S) {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 0;

            .menu {
                width: 50%;
                border-bottom: 2px solid #215732;
                flex-basis: auto;

                &:nth-child(even) {
                    border-right: 0;
                }
            }
        }
    }
}

.menus-wrapper {
    padding-bottom: 6em;
    border-left: 2px solid $color-body;
    border-right: 2px solid $color-body;
    position: relative;
}

.menus-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 6em;
    z-index: -1;

    .menu-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        @extend %transition;

        &.show {
            opacity: 1;
            visibility: visible;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.motley-ctas-wrapper {
    margin-bottom: 6em;
    margin-left: calc(-3.5em + -2px);
    margin-right: calc(-3.5em + -2px);

    .slick-slide {
        padding: 0 0.5em;
    }
    .slider-prev {
        left: -2.75em;
    }
    .slider-next {
        right: -2.75em;
    }
}

.motley-cta {
    position: relative;
    color: $color-body-cream;
    text-align: center;
    padding: 3em 1.5em 1em 1.5em;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(0,0,0,0.5);
        z-index: -1;
        @extend %nav-transition;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        @extend %nav-transition;
    }
    h5 {
        width: 100%;
    }
    p {
        font-size: 0.777em;
        width: 100%;
    }
    &:hover {
        img {
            transform: scale(1.1) rotate(3deg);
        }
        &:after {
            background: rgba(0,0,0,0.3);
        }
    }
}

@include breakpoint(XS) {
    .page-template-page-location {
        .location-content {
            &.subpage {
                .builder-elements {
                    .row {
                        > * {
                            display: none;
                        }
                        .features-grid {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.countdown-container {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;

    &.padding-reduced {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

.home-condensed-top {
    position: relative;
    z-index: 3;
    margin-bottom: 0;

    &.img-content .content {
        align-items: flex-start;
    }
    @include breakpoint(XS) {
        &.img-content .img img {
            height: 20rem;
            width: 20rem;
        }
        .img-with-blob {
            margin-bottom: 22rem;

            .inner-img {
                left: auto !important;
                right: -1rem;
                top: 6rem !important;
            }
        }
    }
}

.img-with-blob {
    transform: translateY(-7rem);

    @include breakpoint(S) {
        transform: translateY(-4rem);
    }
    @include breakpoint(XS) {
        transform: none;
    }
    &.group-page {
        transform: translateY(2rem);

        @include breakpoint(S) {
            transform: translateY(4rem);
        }
        @include breakpoint(XS) {
            transform: none;
        }
    }
    .inner-img {
        mask: url(/wp-content/themes/qbic/autoload-blobs/shape-03.svg);
        mask-size: 100% 100%;
        z-index: 2;

        @include breakpoint(XS) {
            transform: translate(1rem, 3rem);
        }
    }
    .blob-container,
    .blob-link {
        width: 22rem;
        height: 22rem;
        position: absolute;
        top: -10rem;
    }
    .blob-container {
        left: -2.5rem;

        @include breakpoint(M) {
            left: -5rem;
        }
        @include breakpoint(S) {
            top: -14rem;
            left: -2.5rem;
        }
        @include breakpoint(XS) {
            top: -10rem;
            left: auto;
            right: 0;
        }
    }
    .blob-link {
        z-index: 3;
        top: 0;
        left: 0;
    }
    .inner-content {
        width: 22rem;
        height: 22rem;
        text-align: center;
        
        .blob-inside {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 1rem 1rem 1rem;
        }
        h3,
        h5 {
            max-width: 16rem;
        }
        h5 {
            margin-bottom: 0.5rem;
        }
        .blob {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
}

// .home-condensed-elements {
//     .blob-row-container {
//         margin-bottom: 0;
//         margin-top: -5rem;

//         .blob-row {
//             margin-bottom: 0;
//         }
//     }
// }

.blob-row-container {
    &.less-spacing {
        margin: -5rem 0 2rem 0;

        .blob-row-wrapper {
            padding: 0;
        }
        .blob-row {
            margin: 0;
        }
    }
}

.text-ticker {
    p {
        font-weight: 900;
        font-size: 5em;
        color: mix(white, $color-pink, 70%);
        margin: 0;
        padding: 0.5rem 1.5rem;
        line-height: 1;

        @include breakpoint(S) {
            font-size: 4em;
        }
        @include breakpoint(XS) {
            font-size: 3em;
        }
    }
}

.jobs-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 5rem;

    .job {
        width: calc(25% - 2rem);
        position: relative;
        text-align: center;
        margin-bottom: 5rem;
        padding-top: 2rem;

        @include breakpoint(S) {
            width: calc(50% - 2rem);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .blob {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .blob-wrapper {
        position: absolute;
        top: 0;
        left: 15%;
        width: 70%;
        height: 100%;
        z-index: -1;
    }
    .blob-overflow {
        @include breakpoint(XS) {
            min-height: 62.5rem;
            overflow-y: scroll;
        }
    }
    .job-availability {
        display: none;

        &.active {
            display: block;
        }
    }
}

.centered-select {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 14rem;
}

.job-openings {
    margin-bottom: 0.75rem;
}

.all-openings {
    font-weight: 300;
}

.explore-cards {
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    @include breakpoint(S) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .slick-prev {
        left: -2.75rem;
    }
    .slick-next {
        right: -2.75rem;
    }
    .slick-arrow {
        padding: 0;
        
        svg {
            display: block;
        }
        @include breakpoint(S) {
            display: none;
        }
    }
}

.explore-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @include breakpoint(S) {
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    p {
        font-size: 1.333rem;
    }
}

.explore-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;

    a {
        margin: 0 0.5rem;
    }
}

.explore-card {
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    @include breakpoint(S) {
        opacity: 0.5;

        &.slick-active {
            opacity: 1;
        }
    }
    @include breakpoint(XS) {
        width: 12rem;
    }
    .explore-card-img {
        margin-bottom: 1rem;
        position: relative;
        height: 0;
        padding-bottom: 100%;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0.5rem;
        }
    }
    .small {
        font-size: 0.777rem;
        line-height: 1.3;
    }
    a {
        display: flex;
        color: $color-body;
        align-items: center;

        svg {
            margin-left: 0.25rem;
            @extend %transition;
        }
        &:hover {
            color: $color-dark-green;
            svg {
                transform: translateX(0.25rem);
            }
        }
    }
}

.blob-cta {
    mask: url(/wp-content/themes/qbic/images/long-cta-1.svg);
    mask-size: 100% 100%;
    position: relative;
    padding: 1.5rem 5rem 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    max-width: 26rem;
    min-height: 7rem;
    margin-bottom: 1rem;

    @include breakpoint(M) {
        max-width: 16rem;
    }
    @include breakpoint(XS) {
        mask: none;
        width: 12rem;
        height: 10rem;
        border-radius: 0.5rem;
        overflow: hidden;
        text-align: center;
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
        justify-content: center;
        flex-shrink: 0;
        margin: 0 0.5rem;

        &:first-child {
            margin-left: 1rem;
        }
    }
    &:nth-child(even) {
        mask: url(/wp-content/themes/qbic/images/long-cta-2.svg);
        mask-size: 100% 100%;

        @include breakpoint(XS) {
            mask: none;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(#000, 0.4);
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    p {
        color: #fff;
        position: relative;
        z-index: 3;
        font-size: 1.777rem;

        @include breakpoint(M) {
            font-size: 1.25rem;
        }
    }
    svg {
        position: absolute;
        right: 1rem;
        top: 50%;
        z-index: 3;
        transform: translateY(-50%);
        @extend %transition;

        @include breakpoint(XS) {
            position: relative;
            right: auto;
            top: auto;
            transform: none;
        }
    }
    &:hover {
        transform: rotate(2deg);

        svg {
            right: 0.5rem;
        }
    }
}

.hotel-details {
    display: flex;
    padding-top: 2rem;
    position: relative;

    @include breakpoint(XS) {
        flex-direction: column;
        padding-top: 0;
    }
    > svg {
        position: absolute;
        top: 3rem;
        width: 200vh;
        height: 200vh;
        left: 60%;
        z-index: -2;

        @include breakpoint(S) {
            top: 0;
            width: 150vh;
            height: 150vh;
            left: 5rem;
        }
        @include breakpoint(XS) {
            top: 50%;
            left: 2.5rem;
        }
    }
    .hotel-ctas {
        width: 26rem;
        margin-right: 4rem;
        flex-shrink: 0;

        @include breakpoint(M) {
            width: 16rem;
            margin-right: 2rem;
        }
        @include breakpoint(XS) {
            width: auto;
            margin-left: -1.75rem;
            margin-right: -1.75rem;
            display: flex;
            overflow: scroll;
        }
    }
    .hotel-info {
        flex-grow: 1;
        padding-top: 2rem;

        @include breakpoint(XS) {
            width: 100%;

            > h3,
            > h4,
            > p {
                text-align: center;
            }
        }
        .icon-list {
            padding-top: 0.5rem;
            margin-bottom: 2rem;
        }
        .hotel-icons-desktop {
            @include breakpoint(XS) {
                display: none;
            }
        }
        .more-icons {
            display: none;
        }
        .hotel-icons-mob {
            display: none;

            ul {
                margin: 0;
            }
            p {
                display: flex;
                justify-content: center;
            }
            .icons-toggle {
                padding-top: 1rem;
            }
            .button {
                svg {
                    @extend %transition;
                }
                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            @include breakpoint(XS) {
                display: block;
            }
        }
    }
}

.wide-blob {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem 1.75rem 2rem;

    @include breakpoint(XS) {
        text-align: center;
    }
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .icon {
        width: 3.75rem;
        height: 3.75rem;
        flex-shrink: 0;
        margin-right: 1.75rem;

        svg {
            width: 3.75rem;
            height: 3.75rem;
            color: $color-body;

            circle,
            path,
            line {
                stroke: $color-body;
            }
        }
        @include breakpoint(XS) {
            display: none;
        }
    }
    p {
        font-size: 0.777rem;
        line-height: 1.3;
    }
    a {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        z-index: 1;
        display: flex;
        align-items: center;

        svg {
            margin-left: 0.25rem;
            @extend %transition
        }
        &:hover {
            transform: translateX(0.5rem);
        }
        @include breakpoint(S) {
            position: static;
            margin-top: 0.5rem;
        }
        @include breakpoint(XS) {
            justify-content: center;
        }
    }
}

.accordion-content-wrap {
    position: relative;
    padding-top: 4rem;

    > svg {
        position: absolute;
        top: 0;
        left: -10rem;
        right: -10rem;
        height: 110%;
        z-index: -1;
        width: calc(100% + 20rem);
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.accordion-content-wrap {
    .accordion-content-inner {
        display: flex;
        align-items: flex-start;

        @include breakpoint(XS) {
            flex-direction: column;
        }
        .accordion-content-left {
            width: 50%;
            position: relative;
            z-index: 5;

            @include breakpoint(XS) {
                width: 100%;
            }
        }
        .accordion-content-right {
            width: 50%;
            padding-left: 4rem;
            padding-top: 2.5rem;
            position: relative;

            @include breakpoint(XS) {
                width: 100%;
                padding-left: 0;
            }
            .bg {
                position: absolute;
                top: -20%;
                left: 2rem;
                width: 150%;
                height: 150%;

                @include breakpoint(XS) {
                    left: -2rem;
                }
            }
            .corner-blob {
                position: absolute;
                bottom: -50%;
                right: -30%;
                z-index: 2;
                transform: rotate(-45deg);

                @include breakpoint(S) {
                    bottom: -60%;
                    right: -60%;
                }
            }
        }
        .accordion-content-right-inner {
            padding-left: 4rem;
            padding-bottom: 2rem;
            position: relative;
            z-index: 3;

            @include breakpoint(M) {
                padding-left: 2rem;
            }
            @include breakpoint(S) {
                padding-left: 1rem;
            }
        }
    }
}

.motley-bg {
    background: $color-body;
}

.motley-block {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.motley-img-carousel-inner {
    border: 2px solid #fff;
    text-align: center;
    color: #fff;

    .top {
        display: flex;
        border-bottom: 2px solid #fff;

        @include breakpoint(S) {
            flex-direction: column;
        }
        .left {
            width: 50%;
            border-right: 2px solid #fff;

            @include breakpoint(S) {
                width: 100%;
                border-right: 0;
            }
        }
        .right {
            width: 50%;
            position: relative;

            @include breakpoint(S) {
                width: 100%;
                height: 20rem;
            }
            @include breakpoint(XS) {
                height: 16rem;
            }
            .motley-carousel-wrapper,
            .motley-carousel,
            .slick-list,
            .slick-track,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .left-top {
        padding: 3rem 2rem;
        border-bottom: 2px solid #fff;

        p {
            font-size: 1.333rem;
            line-height: 1.3;
        }
    }
    .motley-menus {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(S) {
            display: none;
        }
        .menu {
            width: 50%;
            flex-grow: 1;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.5rem 1.5rem 1.25rem 1.5rem;
            flex-direction: column;
            @extend %transition;

            &:nth-child(1),
            &:nth-child(2) {
                border-top: 0;
            }
            &:nth-child(even) {
                border-right: 0;
            }
            &:hover {
                cursor: pointer;
                background: $color-dark-green;
            }
        }
        .menu-content {
            p {
                font-size: 0.75rem;
                line-height: 1.3;
            }
            .button {
                font-size: 0.75rem;
            }
        }
    }
    .bottom {
        display: flex;

        @include breakpoint(S) {
            display: block;
        }
        .left {
            width: 60%;
            border-right: 2px solid #fff;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include breakpoint(S) {
                width: 100%;
                border-right: 0;
                border-bottom: 2px solid #fff;
            }
        }
        .right {
            width: 40%;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(S) {
                width: 100%;
                padding: 0;
            }
            .button {
                margin: 0 0.5rem;

                @include breakpoint(XS) {
                    margin: 0;

                    &.transparent {
                        border: 0;
                    }
                }
            }
        }
    }
    .motley-controls {
        bottom: auto;
        top: 0;
        left: auto;
        right: -3rem;
        position: absolute;

        @include breakpoint(S) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            bottom: 1rem;
            right: 0;
            left: 0;
            top: auto;
        }
    }
    .motley-prev {
        cursor: pointer;

        @include breakpoint(XS) {
            order: 1;
        }
    }
    .motley-next {
        cursor: pointer;
        margin-top: 0.5em;

        @include breakpoint(XS) {
            margin-top: 0;
            order: 3;
        }
    }
    .motley-pager {
        font-size: 0.777em;
        margin-top: 1rem;
        position: relative;
        width: 2.5em;
        min-width: 0;

        @include breakpoint(XS) {
            margin: 0 1em;
            order: 2;
        }
        span {
            margin: 0;
            display: block;

            &.slide-no {
                text-align: left;
            }
            &.total-slides {
                text-align: right;
            }
        }
        .divider {
            position: absolute;
            height: 1px;
            width: 100%;
            top: 0.5em;
            right: 0.5em;
            transform: rotate(-45deg);
            background: $color-body-cream;
            transform-origin: top right;
        }
    }
}

// design my night widget for motley
body {
    #dmn-partner-widget {
        margin: 0 auto;
        color: $color-body;

        #dmn-widget-inner {
            border-radius: 0;
            border: 2px solid $color-body;
            box-shadow: none;

            .dropdown {
                &:after {
                    display: none;
                }
            }
        }
        .list-slot .list-slot__title,
        #dmn-widget-inner .time-header,
        .stage .dropdown.dropdown--open .dropdown__title {
            color: $color-body;
            font-weight: normal;
        }
        #dmn-widget-inner .date-selector .date-selector__quick-buttons button {
            color: $color-body;
            font-weight: normal;
        }
    }
}